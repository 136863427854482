
::-webkit-scrollbar {width:6px;}
::-webkit-scrollbar-track { background: #e8e8e8;}
::-webkit-scrollbar-thumb:hover {background: #fb5496;}
::-webkit-scrollbar-thumb {
background-image: -webkit-linear-gradient(45deg, #ffbd84, #ff1f8e);
background-image: -o-linear-gradient(45deg, #ffbd84, #ff1f8e);
background-image: linear-gradient(45deg, #ffbd84, #ff1f8e);}
/*Default css*/
::-moz-selection {background: rgba(166, 175, 189, 0.3);}
::selection {background: rgba(166, 175, 189, 0.3);}
*{ -webkit-font-smoothing: antialiased;
-moz-os-font-smoothing: grayscale;
box-sizing: border-box;
padding: 0;
margin: 0;
outline:none;
vertical-align:baseline;}
body {font-family: 'Open Sans', sans-serif; color: #6a6a8e; font-weight: 400;   font-style: normal; line-height:1; font-size: 17px; outline:none;overflow-x: hidden;}
article, aside, details, figcaption, figure, footer, header, nav, section, summary {  display: block; }
h1, h2, h3, h4, h5, h6 {font-family: 'Poppins', sans-serif; color: #050748; font-weight: 600;}
h1, h2, h3, h4, h5, h6, p, ul{padding: 0;   margin: 0;}
ul{list-style:none;}
a:hover { text-decoration: none;}
button:focus {outline: none!important}
.fa-icon{    margin-left: 5px; font-size: 12px;}
.header-heading .fa-icon{    margin-left: 5px; font-size: 12px; animation: 2s infinite arrowanim; -webkit-animation: 2s infinite arrowanim;}
.common-heading{padding: 0;    text-align: center;    position: relative;z-index: 10;}
.common-heading h2 {        margin-bottom: 60px;}
.inner-heading h2 {     margin-bottom: 30px;}
.v-center{align-self: center; align-items: center;}
button{ position: relative;    overflow: hidden;}
.lnk{position: relative;    overflow: hidden;}
.animation, a, .isotope_item, input, button, .custom-btn, .circle,
.s-block, .clients-logo, .icon-set, .link-hover, .sticky, .navbar, .dropdown-toggle, .hover-scale img, .sub-menu-sec a,.main-header.sticky, .main-header, .circle2, .hoshd{
-webkit-transition: 1.2s cubic-bezier(.17, .85, .438, .99);
-o-transition: 1.2s cubic-bezier(.17, .85, .438, .99);
transition: 1.2s cubic-bezier(.17, .85, .438, .99);
/*-moz-transition: all 0.4s;
-o-transition: all 0.4s;
-webkit-transition: all 0.4s;
transition: all 0.4s;*/}
.no-shadow{box-shadow:none !important}
span.circle{background: rgba(255, 255, 255, 0.2); display: block; position: absolute; top: -10%; right: -130px; width: 200px; height: 200px; border-radius: 50%;}
.lnk:hover span.circle{ top: -10%;    right: -80px;}
span.circle2{background: rgba(255, 255, 255, 0.2); display: block; position: absolute; top: -10%; right: -50px; width: 80px; height: 80px; border-radius: 50%;}
.lnk:hover span.circle2{ top: -10%;    right: -40px;}
.font-15{font-size:15px;}
.f-medium{ font-weight:600}
.f-bold{ font-weight:700}
.light-blue{background: #f8fdff;}
.text-radius { border-radius: 3px;    padding: 0 3px;}
.text-primary { color: #fe4c1c;}
.text-second{color: #E91E63;}
.text-bold{font-weight:bold}
/**Header section**/
.header-icon-nav a{ background: #ffffff7a;    padding: 12px;   margin: 5px;   border-radius: 50%;   color: #0a2233;}
.sticky .dsk-logo { line-height: 70px;}
.sticky .custom-nav .nav-list li a.menu-links { line-height: 70px;}
.main-header.sticky  {height: 80px;}
.top-header{position:relative; z-index:999;}
.navbar .mobile-menu{ display:none;}
.navbar .mobilemenu{color: #0a2233;    top: -4px;    position: relative;}
.navbar .mobilemenu i{    font-size: 25px;}
.mob-nav{    display: flex;}
.mob-nav li{ position: relative;}
.ml8{margin-left: 8px; }
.animate {animation-duration: 0.4s;   -webkit-animation-duration: 0.4s;   animation-fill-mode: both;   -webkit-animation-fill-mode: both;}
.top-header .dropdown-toggle::after {
display: inline-block;
margin-left: 3px;
vertical-align: baseline;
content: "+";
border-top: 0;
border-right: 0;
border-bottom: 0;
border-left: 0;
border-radius: 100px;}
.slideIn {  -webkit-animation-name: slideIn;    animation-name: slideIn;}
.dropdown-item:focus, .dropdown-item:hover {
color: #16181b;
text-decoration: none;
background-color: #ffffff;
outline:none;}
.dropdown-item.active, .dropdown-item:active {color: #0a2233;   text-decoration: none;   background-color: #ffffff; outline:none;}
.dropdown-menu { border: 0px solid rgba(0,0,0,.15);}
header .dropdown-menu {
position: absolute; top: 100%; left: 0; z-index: 1000; display: none; float: left; min-width: 10rem; padding: 10px 0; margin: 0; font-size: 16px; color: #212529;
text-align: left; list-style: none; background-color: #fff; background-clip: padding-box; border: 0; border-top: 0px solid rgb(0, 186, 255);
border-radius: 0px 0px 8px 8px;
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.dropdown-item { display: block; width: 100%; clear: both;  font-weight: 400; text-align: inherit; white-space: nowrap; background-color: transparent;
border: 0; font-size: 15px; color: #0a2233; font-family: 'Poppins', sans-serif; font-weight: 500; padding: 10px 15px;}
.navbar-toggler { background-color: #fff; border: 1px solid #fff;}
.top-header  .sticky{ background: #fff; -webkit-box-shadow: 0 4px 6px 0 rgba(12, 0, 46, .08); box-shadow: 0 4px 6px 0 rgba(12, 0, 46, .08); height: 70px;}
.nav-item.dropdown:hover{background: #fff}
.list-mnu li a{white-space: break-spaces; line-height: 24px; padding: 5px 10px;}
.navbar-light .navbar-nav .nav-link {
font-weight: 600;
font-size: 16px;
color: #0a2233;
font-family: 'Poppins', sans-serif;
font-weight: 500;
padding: 0px 15px;
line-height: 48px;}
.fixed-top { position: fixed; top: 0; right: 0; left: 0; z-index: 1030; padding-top: 0; padding-bottom: 0; height: 100px;  }
.brand-logo a{display: block; }
.top-header  .navbar .custom-btn{   padding: 0px 32px;    color:#fff;   font-weight: 400;}
.top-header  .navbar .custom-btn:hover, .top-header  .navbar .custom-btn:focus{ color:#fff;}
.modal-backdrop {background: linear-gradient(135deg, #0c0004 0%,#000e0c 100%);}
.modal {z-index: 999999;}
.abt{position: absolute;    right: 15px !important;    top: 0px !important;    z-index: 9999999;}
.popup-modalfull .modal-dialog {min-height: 100%; margin: 0 0 0 auto; background: #fff;     width: 100%;    max-width: 100%;    height: 100%;    margin: 0; }
.popup-modalfull .modal-content{border: 0;height: 100%;  border-radius: 0;}
.popup-modalfull .modal-body { overflow-y: auto;}
/**mega menu header **/
.main-header.sticky .nx-dropdown { top: 65px;}
.nav-bg-b .custom-nav .nav-list li a.menu-links {  color: #fff;}
.main-header.sticky{
background: #fff;
-webkit-box-shadow: 0 4px 6px 0 rgba(12, 0, 46, .08);
box-shadow: 0 4px 6px 0 rgba(12, 0, 46, .08);}
.main-header  {
padding: 0 20px;
height: 85px;
z-index: 99999;
border-bottom: 1px solid rgba(255, 255, 255, 0.1);}
.fa-ani {
margin-left: 5px;
font-size: 12px;
animation: 2s infinite arrowanim;
-webkit-animation: 2s infinite arrowanim;}
.sub-menu-center-block{display: flex;}
.menu-header{
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;}
.right-bddr{    border-right: 1px solid rgba(255, 255, 255, 0.1);
padding: 0px 10px 0 0px !important;
margin: 0 20px 0 0;}
.custom-nav .sbmenu > a::before {content: ""; position: absolute; right: 4px;}
.custom-nav .sbmenu.hover > a::before { content: "-"; }
.main-header.sticky .custom-nav .nav-list li a.menu-links { color: #050748;}
.main-header.sticky  .mega-white-logo{display:none;}
.main-header  .mega-white-logo{display:none;}
.nav-bg-b.main-header.sticky  .mega-white-logo{display:none;}
.nav-bg-b.main-header  .mega-white-logo{display:inline-block;}
.nav-bg-b.main-header .mega-darks-logo{display: none;}
.main-header.sticky .mega-darks-logo{display: inline-block;}
.dsk-logo{float: left; line-height: 85px;}
.dsk-logo .nav-brand img{max-height: 75px;}
.custom-nav{float: right;}
.custom-nav .menu-dorpdown{width:240px; margin: 0 auto;}
.custom-nav .smfull{width:100%;}
.custom-nav .nav-list li{display: inline-flex;}
.custom-nav .nav-list li.rpdropdown{position: relative;}
.custom-nav .nav-list li a.menu-links{
font-size: 16px;
color: #050748;
font-family: 'Poppins', sans-serif;
font-weight: 500;
padding: 0px 15px;
line-height: 85px;
text-transform: uppercase;
position: relative;
overflow: hidden;}
.btn-br{line-height: 48px; color: #fff; border-radius: 100px; padding: 0 20px;}
.btn-br:hover{color:#fff;}
.btn-round-{
width: 48px;
height: 48px;
text-align: center;
line-height: 48px;
padding: 0;
margin-right: 8px;
position: relative;
display: inline-block;}
.btn-round-.trngl:after{
content: '';
position: absolute;
right: -4px;
top: 50%;
width: 0;
height: 0;
border-left: 6px solid transparent;
border-right: 6px solid transparent;
border-top: 8px solid #e8e8e8;
clear: both;
transform: translateY(-50%);}
.btshad-b1{
-webkit-box-shadow: 0 10px 15px 0px rgba(175, 0, 87, .2);
box-shadow: 0 10px 15px 0px rgba(175, 0, 87, .2);}
.btshad-b2{
-webkit-box-shadow: 0 10px 15px 0px rgba(56, 0, 189, .2);
box-shadow: 0 10px 15px 0px rgba(56, 0, 189, .2);}
.nx-dropdown {
position: absolute;
z-index: 1;
left: 0;
right: 0;
top: 80px;
opacity: 0;
height: 0;
overflow: hidden;
-webkit-transition: all .5s ease;
-moz-transition: all .5s ease;
-ms-transition: all .5s ease;
-o-transition: all .5s ease;
transition: all .5s ease;}
div.custom-nav > ul > li:hover .nx-dropdown {
opacity: 1;
height: auto;
overflow: inherit;}
.submenu-btnset{
position: relative;
display: block;
top: 50%;
-ms-transform: translateY(-50%);
transform: translateY(-50%);
white-space: normal;}
.sub-menu-column {
width: 25%;
float: left;
padding: 0 20px;}
.sub-menu-column .menuheading {
color: #050748;
font-weight: 700;
font-size: 18px;
letter-spacing: .2px;
margin: 0 0 16px 0;
padding: 0 0 14px 0;
border-bottom: 1px solid #dadada;}
.sub-menu-column ul {width: 100%; display: inline-block; position: relative; }
.sub-menu-column ul li {
width: 100%;
height: auto;
float: left;
padding: 0;
margin-right: 0;
margin-bottom: 9px;}
.sub-menu-column ul li a {
width: 100%;
font-size: 15px;
color: #050748;
position: relative;
line-height: 25px;
font-weight: 600;}
.sub-menu-section {
box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 0.04);
border-top: 2px solid #f3f3f3;
width: 100%;
padding:40px 0;
display: inline-block;
background-color: #ffffff;
margin-top: 4px;}
.btn-block a{
min-width: 230px;
text-align: center;
-webkit-box-shadow: 0 10px 15px 0px rgba(47, 0, 16, 0.15);
box-shadow: 0 10px 15px 0px rgba(47, 0, 16, 0.15);}
.bgtspnt{background: transparent !important;}
div.custom-nav > ul > li.sbmenu > a:after {
content: '';
position: absolute;
width: 100%;
height: 5px;
background: #4f4daf;
left: -100%;
bottom: 0px;
opacity: 0;
-webkit-transition: all .5s ease;
-moz-transition: all .5s ease;
-ms-transition: all .5s ease;
-o-transition: all .5s ease;
transition: all .5s ease;}
div.custom-nav > ul > li.sbmenu:hover > a:after  {left: 0; opacity: 1;}
.contact-show{position: relative;}
.contact-show:hover .contact-inquiry{ display: block;}
.contact-inquiry {
width: 285px;
position: absolute;
top: 48px;
right: 0;
z-index: 99;
display: none;
transition: all 2s linear;}
.hrbg{background: #e4e8ff;}
.contact-info- .contct-heading {
font-size: 19px;
color: #3c4147;
font-weight: 700;
padding: 11px 20px;
letter-spacing: .2px;}
.contact-info- .inquiry-card-nn .title-inq-c {font-size: 14px; font-weight: bold;}
.contact-info- .inquiry-card-nn ul li a {
font-size: 16px;
color: #050748;
font-weight: 700;
text-decoration: none;}
.contact-info- .inquiry-card-nn {
border-top: 1px solid #e1e1e2;
position: relative;
padding: 15px 20px;
text-align: left;}
.contact-info- {
width: 100%;
display: inline-block;
padding: 0;
background: #fff;
border-radius: 5px;
margin-top: 20px;
position: relative;
box-shadow: 0 0 5px rgba(0,0,0,.3);}
.contact-info- .inquiry-card-nn ul li {
list-style: none;
margin: 14px 0;
text-align: left;
display: block;}
.contact-info- .inquiry-card-nn ul li.mb0 {margin-bottom: 0;}
.contact-info- .inquiry-card-nn ul li i{font-size: 20px; margin: 0 11px 0 0px; color: #8BC34A;}
.contact-info- .inquiry-card-nn span {font-size: 13px; color: #828282;}
.contact-info- .inquiry-card-nn .fa-envelope{color: #012169;}
.contact-info-:after {
content: '';
position: absolute;
border-left: 7px solid transparent;
border-right: 7px solid transparent;
border-top: 7px solid transparent;
border-bottom: 7px solid rgba(255,255,255,.95);
top: -14px;
right: 22px;
transition: all 1s ease;
-moz-transition: all 1s ease;
-webkit-transition: all 1s ease;}
/**circle button effect**/
a.play-video {
min-width: 70px;
min-height: 70px;
display: inline-flex;
background: #ffffff;
border-radius: 1000px;
transition: all 0.3s ease-in-out 0s;
cursor: pointer;
outline: none;
position: relative;
padding: 10px;
align-items: center;}
a.play-video::after {
content: '';
width: 70px;
height: 70px;
border-radius: 100%;
border: 6px solid #ffffff;
position: absolute;
z-index: 0;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
animation: ring-ani 1.75s infinite cubic-bezier(0.66, 0, 0, 1);
box-shadow: 0 0 0 0 rgba(12, 108, 211, 0.5);}
@-webkit-keyframes ring-ani {to {box-shadow: 0 0 0 18px rgba(12, 108, 211, 0);}}
.triangle-play {
width: 0;
height: 0;
border-top: 15px solid transparent;
border-left: 25px solid #ff2d8d;
border-bottom: 15px solid transparent;
text-align: center;
margin: 0 auto 0;}
/****/
.hero-card-web{
position: relative;
overflow: hidden;
padding-top: 150px;
padding-bottom: 100px;
height: 100vh;
align-items: center;
display: flex;}
.hero-main-rp {padding: 0 30px 0 180px;}
.hero-heading-sec h2{
color: #fff;
font-size: 55px;
line-height: 67px;
text-transform: uppercase;
font-weight: 700;}
.hero-heading-sec h2 span{display: block;}
.hero-heading-sec > p{color: #fff000; font-size: 18px; max-width: 400px; margin: 40px 0;}
.flags-size {width:25px;}
.awards-block-tt{margin-top: 40px;}
.hero-content-sec{display: inline-flex; align-items: center;}
.title-hero-oth p{font-size: 20px; color: #fff; margin: 0 0 0 25px; text-transform: uppercase; }
.title-hero-oth p span{display: block; color: #fff000;}
.hero-service-cards {margin-top: 195px;}
.service-slide{border-radius: 30px; max-width: 280px; overflow: hidden;margin: 20px 0;  transform-style: preserve-3d; transform: perspective(1000px);}
.service-card-hh{transform: translateZ(20px);    display: block;}
.image-sr-mm{
height: 250px;
width: 100%;
padding: 0px 0 0px 0;
position: relative;
margin: 0 auto;}
.image-sr-mm img{
position: absolute;
bottom: 0;
left: 0;
right: 0;
top: 0;
margin: 0 auto 0;
max-width: 100%;
max-height: 100%;
width: initial !important;}
.title-serv-c{color: #fff; font-size: 22px; font-weight: 700; line-height: 1.2; padding: 20px 20px 35px;}
.title-serv-c span {   display: block;}
.card-bg-a{
background: #3faee5;
background: -moz-linear-gradient(top,#3faee5 0,#6d03ce 100%);
background: -webkit-linear-gradient(top,#3faee5 0,#6d03ce 100%);
background: linear-gradient(to bottom,#3faee5 0,#6d03ce 100%);}
.card-bg-b{
background: #ff92f6;
background: -moz-linear-gradient(top,#ff92f6 0,#b300a5 100%);
background: -webkit-linear-gradient(top,#ff92f6 0,#b300a5 100%);
background: linear-gradient(to bottom,#ff92f6 0,#b300a5 100%);}
.card-bg-c{
background: #3e35b7;
background: -moz-linear-gradient(top,#3e35b7 0,#4c4aff 100%);
background: -webkit-linear-gradient(top,#3e35b7 0,#4c4aff 100%);
background: linear-gradient(to bottom,#3e35b7 0,#4c4aff 100%);}
.card-bg-d{
background: #f72c8c;
background: -moz-linear-gradient(top,#f72c8c 0,#b82dcf 100%);
background: -webkit-linear-gradient(top,#f72c8c 0,#b82dcf 100%);
background: linear-gradient(to bottom,#ffa76a 0,#ff8e38 100%);}
.card-bg-e{
background: #5782ea;
background: -moz-linear-gradient(top,#5782ea 0,#2251af 100%);
background: -webkit-linear-gradient(top,#5782ea 0,#2251af 100%);
background: linear-gradient(to bottom,#5782ea 0,#2251af 100%);}
.card-bg-f{
background: #95e5ff;
background: -moz-linear-gradient(top,#95e5ff 0,#009688 100%);
background: -webkit-linear-gradient(top,#95e5ff 0,#009688 100%);
background: linear-gradient(to bottom,#95e5ff 0,#009688 100%);}
.shape-bg3:before{
content: "";
background: url(../images/shape/shape-12.svg);
position: absolute; height: 100%; width: 100%; opacity: 0.03;}
/**Hero section**/
.hero-bg-bg1:before{
content: "";
background: url(../images/hero/banner-shap-2.png);
position: absolute;
height: 100vh;
right: 0;
left: 0;
top: 0;
width: 100%;
background-position: left 0;
background-size: contain;
background-repeat: no-repeat;}
.hero-section {
position: relative;
overflow: hidden;
padding-top: 100px;
padding-bottom: 100px;
height: 100vh;
align-items: center;
display: grid;}
.btn-main:hover{color:#fff;}
.btn-main {
font-size: 16px;
font-weight: 400;
font-family: 'Poppins', sans-serif;
padding: 0px 32px;
line-height: 56px;
text-align: center;
outline: none;
color: #fff;
background-color: #ff1f8e;
border: none;
-webkit-border-radius: 100px;
border-radius: 100px;
display: inline-block;
position: relative;
-webkit-box-shadow: 0 10px 15px 0px rgba(233, 30, 99, .15);
box-shadow: 0 10px 15px 0px rgba(233, 30, 99, .15);}
.header-heading h1{ font-size: 60px;   line-height: 72px; font-weight: 700;  margin-bottom: 30px;}
.header-heading p{font-size: 20px;  line-height: 30px;  margin-bottom: 35px;}
/**About section**/
.about-agency .common-heading h2 {margin-bottom: 20px;}
.about-agency{position: relative;   overflow: hidden;}
.about-agency:before {
content: "";
background: url(../images/shape/dot_bg.png);
position: absolute;
width: 100%;
height: 100%;
top: 0;}
.text-l{text-align:left !important;}
.common-heading > span {
color: #e60072;
text-transform: uppercase;
letter-spacing: 0.5px;
font-size: 16px;
font-weight: 400;
margin: 0 0 15px 0;
display: block;}
.quote{
font-weight: 500;
padding: 25px;
position: relative;
border-left: 4px solid #ff358f;
margin: 30px 0;
background: #FAFAFB;
color: #6a6a8e;
font-family: 'Poppins', sans-serif;}
.user- .user-image img{ border-radius: 100%;    width:80px}
.user-info{ margin: 0px 0 0 20px;text-align: left;}
.user-info p span{font-weight: 700;}
/**Service section**/
.service-section {background: #fff;   position: relative; overflow: hidden;}
.image-block img{width:100%; border-radius: 8px;}
.img-block img{width:100%; border-radius: 8px;}
.white-text{    color: #fff !important;}
.shape-num .shape-loc:first-child:before{
content: '';
background: url(../images/shape/shape-1.svg);
background-size: auto;
position: absolute;
height: 150px;
width: 150px;
background-repeat: no-repeat;
left: -40px;
bottom: -60px;
z-index: -1;}
.shape-num .shape-loc:last-child:after{
content: '';
background: url(../images/shape/shape-2.svg);
background-size: auto;
position: absolute;
height: 150px;
width: 150px;
background-repeat: no-repeat;
right: -40px;
bottom: -60px;
z-index: -1;}
.shape-bg2 img{
z-index: 1;
position: relative;}
.shape-bg2{ position: relative;}
.shape-bg2:after {
content: '';
background: url(../images/shape/shape-2.svg);
background-size: auto;
position: absolute;
height: 150px;
width: 150px;
background-repeat: no-repeat;
right: -40px;
bottom: -60px;
z-index: -1;}
.shape-bg2:before {
content: '';
background: url(../images/shape/shape-1.svg);
background-size: auto;
position: absolute;
height: 150px;
width: 150px;
background-repeat: no-repeat;
left: -40px;
bottom: -60px;
z-index: 0;}
.service-section:before{
content: "";
background: url(../images/shape/dot-shape.png) #d7f0fd;
position: absolute;
height: 400px;
right: 0;
left: 0;
top: 0;
width: 100%;
background-position: center top;
background-size: contain;
background-repeat: repeat;}
.s-block{
background: #fff;
text-align: center;
background-size: 200%;
background-position: top right;
background-repeat: no-repeat;
text-align: center;
padding: 50px 20px;
margin:0;
position: relative;
border: 1px solid #d4f0ff;
-webkit-border-radius: 8px;
border-radius: 8px;
-webkit-box-shadow:
0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059),
0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071),
0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow:
0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059),
0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071),
0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.service-section .s-block{
background: url(../images/shape/shape-7.svg) #fff;
background-size: 180px;
background-position: top right;
background-repeat: no-repeat;}
.s-card-icon {  width: 80px;    margin: 0 auto 30px;}
.s-block h4 {       margin: 0 0 10px 0;}
.s-block a{ display: inline-block;    margin: 30px 0 0 0;    color: #050748;    font-family: 'Poppins', sans-serif;}
.upset{position:relative;z-index:5}
/**Service index2**/
.service-2:before{background-color:#f9f9f9}
.wide-block{
padding: 40px 30px;
margin: 5px 0;
position: relative;
border: 0px solid #d4f0ff;
-webkit-border-radius: 8px;
border-radius: 8px;
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.service-img1{     background:#e9ddff;   }
.service-img1:after{        background: url(../images/service/service-img-1.png); }
.service-img2{    background:#ffdadb;}
.service-img2:after{    background: url(../images/service/service-img-2.png);}
.service-img3{  background:#d6edff; }
.service-img3:after{    background: url(../images/service/service-img-3.png);}
.service-img4{  background:#ffede1;   }
.service-img4:after{    background: url(../images/service/service-img-4.png);}
.wide-block:after{
content:'';
position: absolute;
right: 0;
top: 0;
height: 100%;
width: 100%;
background-size: 280px;
background-position: right center;
background-repeat: no-repeat;}
.block-space- {    max-width: 50%;    position: relative;    z-index: 555;}
.wide-block h4{     margin: 20px 0 30px 0;}
/**Statistics section**/
.tilt3d .statistics-img {transform-style: preserve-3d;    transform: perspective(1000px);}
.tilt3d .statistics-img img{transform: translateZ(20px)}
.statistics{    align-items: center;    display: flex;}
.statistics p{  font-size: 20px;    margin: 10px 0 0 0; font-weight: 500;}
.statistics span{
font-size: 45px;
font-family: 'Poppins', sans-serif;
color: #050748;
font-weight: 700;
display: inline-block;}
.statistics-img {
width: 100px;
height: 100px;
float: left;
padding: 15px;
background: #fff;
border-radius: 8px 40px 8px 40px;
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.statnumb {  margin: 0 0 0 20px;    text-align: left;    float: left;}
.small .statistics-img {  width: 75px;   height: 75px;}
.t-ctr{text-align:center;}
.small .statistics span{font-size: 35px; color: #050748;}
.in-stats .statistics span{ font-size: 35px;color: #ff1f8e;}
.small .statistics p{   font-size: 18px; line-height: 24px;}
.small{ margin-top: 100px;}
.in-stats .statistics{  text-align:left;}
.card-stats .statistics{
padding: 40px;
text-align:center;
border-radius: 8px;
background: #ffffff;
-webkit-box-shadow:  -30px 30px 60px #f2f2f2,30px -30px 60px #ffffff;
box-shadow:  -30px 30px 60px #f2f2f2,30px -30px 60px #ffffff;}
/**Portfolio section**/
.portfolio-section{ background: #fff; position: relative;}
.portfolio-section:before{
content: "";
background: url(../images/shape/dot-pattern.png);
position: absolute;
height: 400px;
right: 0;
left: 0;
top: 0;
background-size: auto;
background-repeat: repeat;}
.isotope_item{  position:relative;}
.item-info{ padding:20px 0 0px 0;}
.isotope_item h4 {font-weight: 600;  margin: 0 0 10px 0;}
.isotope_item h4 a{ color: #050748;}
.item-image{
display: block;
width: 100%;
border-radius: 8px;
overflow: hidden;
background: #f3f9ff;
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.item-image img {  width: 100%;}
.portfolio-block{   position: relative;    padding:50px;    border-radius: 8px;    display: flex;    align-items: center;    justify-content: space-between;}
.portfolio-item-info h3 a{color: #050748;}
.portfolio-item-info{    width: 50%; padding-right: 40px}
.portfolio-item-image{width: 50%;text-align: center;}
.portfolio-item-info .-reviewr {   width: 50px;   height: 50px;   border-radius: 100px;   overflow: hidden;   margin-right: 15px;}
.portfolio-item-info .review-text {   padding: 0px 0 20px 0;}
.portfolio-item-info .reviews-card {       background: rgba(255, 255, 255, 0.6);    padding: 25px;    border-radius: 8px;}
/**Clients section**/
.clients-logo{
background: #ffffff;
padding: 0px 15px;
max-height: 150px;
min-height: 150px;
margin: 0 15px;
border: 1px solid #d4f0ff;
align-items: center;
display: flex;
position: relative;
border-radius: 8px;
-webkit-box-shadow: -20px 20px 40px #ece0f2, 20px -20px 40px #fff8ff;
box-shadow: -20px 20px 40px #ece0f2, 20px -20px 40px #fff8ff;}
.clients-section{
background: #fffaf5;
background: -moz-linear-gradient(top, #fffaf5 0%, #f8ecff 100%);
background: -webkit-linear-gradient(top, #fffaf5 0%,#f8ecff 100%);
background: linear-gradient(to bottom, #fffaf5 0%,#f8ecff 100%);}
.clients-logo img{margin: 0 auto;    display: block;    width: 160px; }
.opl ul {display: contents;}
.opl ul li {   width: 20%;   float: left;   position: relative; }
/**Work category section**/
.work-category{
background: #fbf9ed;
background: -moz-linear-gradient(top, #fbf9ed 0%, #fbeaf8 100%);
background: -webkit-linear-gradient(top, #fbf9ed 0%,#fbeaf8 100%);
background: linear-gradient(to bottom, #fbf9ed 0%,#fbeaf8 100%);
position: relative;}
.work-category .common-heading h2{  margin-bottom: 30px;}
.work-card p{
color: #050748;
font-family: 'Poppins', sans-serif;
line-height: 24px;
font-size: 16px;
margin: 5px 0 0 0;
padding: 0 12px}
.icon-bg{display: block;   height: 62px;   width: 62px;  margin: 0 auto;  text-align: center;}
.icon-set{width: 25%; display: inline-block;   float: left;   position: relative;}
.work-card {
margin: 10px;
padding: 30px 20px;
max-height: 170px;
text-align: center;
min-height: 170px;
border-radius:8px 40px 8px 40px;
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.cd1{background:#70e4e8;}.cd2{background:#f5eb92;}.cd3{background:#a3ffe2;}.cd4{background:#ffbc7a;}.cd5{background:#e3ff8e;}.cd6{background:#ffa0c6;}
.cd7{background:#f3a6ff;}.cd8{background:#97ffaf;}.cd9{background:#6cfffa;}.cd10{background:#77baff;}.cd11{background:#ffefd4;}.cd12{background:#7affdb;}
/**Testimonial section**/
.testinomial-section{
background: url(../images/shape/shape-9.svg) #fff;
background-size: contain;
background-position: center left;
background-repeat: no-repeat;}
.testimonial-card .t-text p{font-size:16px; line-height:28px;}
.testinomial-section .owl-carousel .owl-dots{
position: absolute;
bottom: 0;
right: 0;
text-align: right;
background: #ffffff;
width: 100px;
margin: 0;
height: 50px;
padding: 25px 0 0 0;}
.user-info p {font-size: 16px;   line-height: 24px;}
.owl-carousel .user-image img {border-radius: 100%;   width: 80px;}
.owl-carousel .owl-dots .owl-dot:before {
top: 2px;
right: 2px;
bottom: 2px;
left: 2px;
opacity: 0;
background: 0 0;
border: 1px solid #ff1f8e;}
.owl-carousel .owl-dots .owl-dot:after, .owl-carousel .owl-dots .owl-dot:before {
content: '';
position: absolute;
border-radius: 50%;
-webkit-transition: ease-out .16s;
transition: ease-out .16s;}
.owl-carousel .owl-dots .owl-dot {position: relative;  display: inline-block;  width: 20px;  height: 20px;  margin: 0 2px; vertical-align: middle;}
.owl-carousel .owl-dots .owl-dot.active:before {    top: 0;    right: 0;    bottom: 0;    left: 0;}
.owl-carousel .owl-dots .owl-dot:after {
top: 50%;
left: 50%;
-webkit-transform: translate(-50%,-50%);
transform: translate(-50%,-50%);
opacity: .4;
background: #ff1f8e;
width: 4px;
height: 4px;}
.owl-carousel .owl-dots .owl-dot.active:after, .owl-carousel .owl-dots .owl-dot.active:before {    opacity: 1;}
.owl-carousel .owl-dots .owl-dot.active:after, .owl-carousel .owl-dots .owl-dot:focus:after, .owl-carousel .owl-dots .owl-dot:hover:after {    opacity: 1;}
/**Enquire form section**/
.light-bgs{background: rgba(255, 255, 255, 0.52); padding: 30px; border-radius: 8px; }
.enquire-form{
background: #D6FFFC;
background: -moz-linear-gradient(top, #D6FFFC 0%, #ffeff5 100%);
background: -webkit-linear-gradient(top, #D6FFFC 0%,#ffeff5 100%);
background: linear-gradient(to bottom, #D6FFFC 0%,#ffeff5 100%);}
.custom-file-label::after {
line-height: 44px;
position: absolute;
top: 0;
right: 0;
bottom: 0;
z-index: 3;
display: block;
border: 0;
height: auto;
padding: 0px 15px;
color: #495057;
content: "Browse";
background-color: #e9ecef;
border-left: inherit;
border-radius: 0;
cursor: pointer; }
.custom-file-label {
position: absolute;
top: 0;
right: 0;
left: 0;
z-index: 1;
padding: 0px 15px;
font-weight: 400;
height: 50px;
line-height: 46px;
background-color: #fff;
border: 2px solid #ced4da;
border-radius: 4px; }
.custom-file {
position: relative;
display: inline-block;
width: 100%;
height: auto;
margin-bottom: 0; }
.modal-backdrop.show { opacity: 0.9; z-index: 99; }
.fdgn2 input, .fdgn2 select, .fdgn2 textarea {border: 2px solid #e6e6e6 !important;}
.form-block input:focus, .form-block textarea:focus{ border-bottom: 2px solid #ff1f8e;}
.form-block input{
width: 100%;
height: 50px;
padding: 0px 15px;
margin: 0px 0 15px 0;
color: #172b43;
font-size: 16px;
outline: none;
background: #fff;
border: 0;
border-bottom: 2px solid #e6e6e6;
-webkit-border-radius: 4px;
border-radius: 4px;}
.form-block textarea{
height: 100px;
width: 100%;
padding: 15px;
margin: 0px 0 15px 0;
color: #172b43;
font-size: 16px;
outline: none;
background: #fff;
border: 0;
border-bottom: 2px solid #e6e6e6;
-webkit-border-radius: 4px;
border-radius: 4px;}
.form-block .form-group { margin-bottom: 0;}
.form-block select {
width: 100%;
height: 50px;
padding: 0px 15px;
margin: 0px 0 15px 0;
color: #172b43;
font-size: 16px;
outline: none;
background: #fff;
border: 0;
border-bottom: 2px solid #e6e6e6;
-webkit-border-radius: 4px;
border-radius: 4px;}
.help-block.with-errors {color: #ff5050; margin: 0px 0 0 10px; font-size: 13px; position: relative; bottom: 12px; }
.messages .alert {
position: relative;
padding: 6px 25px 6px 6px;
margin-bottom: 1rem;
border: 1px solid transparent;
border-radius: .25rem;
font-size: 14px;}
.form-block .messages button:hover {background-color: transparent;}
.messages .close {
font-size: 1.5rem;
font-weight: 700;
line-height: 1;
color: #000;
text-shadow: 0 1px 0 #fff;
opacity: .5; text-align: right; position: absolute; right: 5px; top: 0;}
.custom-control-input:checked~.custom-control-label::before {color: #fff;   border-color: #ff1f8e; background-color: #ff1f8e;}
.custom-control-input:focus~.custom-control-label::before {
-webkit-box-shadow: 0 3px 6px rgba(91,130,148,.08);
box-shadow: 0 3px 6px rgba(91,130,148,.08);}
.custom-control-input:focus:not(:checked)~.custom-control-label::before {border-color: #ffbc80;}
.custom-control-label::before {
position: absolute;
top: -1px;
left: -1.5rem;
display: block;
width: 20px;
height: 20px;
pointer-events: none;
content: "";
background-color: #fff;
border: #adb5bd solid 1px;}
.custom-control-label::after {
position: absolute;
top: -3px;
left: -26px;
display: block;
width: 25px;
height: 25px;
content: "";
background: no-repeat 50%/50% 50%;}
.custom-control { margin-bottom: 10px;}
.form-block label input {height: 20px;  width: 20px;    position: relative; top: 5px; margin: 0 5px 0 0;}
.fieldsets label{
position: relative;
top: -14px;
font-size: 14px;
color: #ff1f8e;
margin: 0;
padding: 0;
display: inline;}
.custom-control label {text-indent: -21px;    padding: 2px 0 0 21px;    font-size: 14px;}
.form-block label a {color: #000;}
.form-block p.trm {font-size: 13px; width: 100%;    text-align: center; margin: 10px 0 0 0;}
.form-block p.trm i{padding: 5px 5px 0 0;}
.form-block button:hover{background-color: #ff1f8e;}
.form-block button {width: 100%;}
/**Our office section**/
.our-office{background: #fff; position:relative; overflow:hidden;}
.shape-bg1, .shape-num, .shape-num{position:relative;}
.shape-numm .shape-loc:first-child:before{
content: '';
background: url(../images/shape/shape-1.svg);
background-size: auto;
position: absolute;
height: 150px;
width: 150px;
background-repeat: no-repeat;
left: -40px;
top: -55px;
z-index: -1;}
.shape-numm .shape-loc:last-child:after{
content: '';
background: url(../images/shape/shape-2.svg);
background-size: auto;
position: absolute;
height: 150px;
width: 150px;
background-repeat: no-repeat;
right: -40px;
top: -55px;
z-index: -1;}
.shape-bg1:before{
content: '';
background: url(../images/shape/shape-1.svg);
background-size: auto;
position: absolute;
height: 150px;
width: 150px;
background-repeat: no-repeat;
left: -40px;
top: -55px;
z-index: -1;}
.shape-bg1:after{
content: '';
background: url(../images/shape/shape-2.svg);
background-size: auto;
position: absolute;
height: 150px;
width: 150px;
background-repeat: no-repeat;
right: -30px;
top: -80px;
z-index: -1;}
.office-text{padding:20px 0 0 0; }
.office-text h4{margin: 0 0 10px 0;  }
.office-text p{ padding: 0 50px 0 0;  margin: 0 0 20px 0;}
.skyline-img{
background: #fbf4f1;
padding: 20px;
border-radius: 8px 40px 8px 0px;
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.-address-list li a{  color: #6a6a8e;}
.-address-list li a i{  width: 25px;}
.-address-list li {line-height: 35px;}
/**scroll to top**/
#scrollUp:hover{opacity: 1}
#scrollUp {
bottom: 20px;
right: 20px;
width: 30px;
height: 50px;
-webkit-border-radius: 100px ;
border-radius: 100px;
color: #fff; opacity: .4;
background: url(../images/icons/up-arrow.svg) #00baff;
background-repeat: no-repeat;
background-size: 15px;
background-position: center center;
background-repeat: no-repeat;}
/**Footer section**/
footer{position: relative; }
.footer-svg{ height: 80px;   overflow: hidden;   margin: -20px 0 0 0px;}
.footer-svg svg path{fill: #FFF0F5;}
footer .row{    position: relative;  z-index: 1;}
footer.ftshap:after{
content: "";
background: url(../images/shape/shape-1.png);
position: absolute;
width: 200px;
height: 370px;
background-repeat: no-repeat;
top: 35%;
z-index: 0;
right: 0;
background-position: center right;
background-size: 80%;
opacity: 0.5;}
footer.ftshap:before{
content: "";
background: url(../images/shape/shape-2.png);
position: absolute;
width: 250px;
height: 250px;
bottom: 0;
z-index: 0;
left: 0;
background-repeat: no-repeat;
background-position: left bottom;
background-size: 100%;
opacity: 0.5;}
.footer-row1 {
background: #fffdf2;
background: -moz-linear-gradient(top, #fffdf2 0%, #ffeff5 100%);
background: -webkit-linear-gradient(top, #fffdf2 0%,#ffeff5 100%);
background: linear-gradient(to bottom, #fffdf2 0%,#ffeff5 100%);
padding:80px 0;}
.footer-row2 {   background: #ffffff;   padding: 50px 0 80px 0;}
.footer-row3 { background: #ffffff;   padding: 40px 0;}
.email-subs h3{  margin-bottom: 10px }
.hline{ border-top: 1px solid #e2eaef;   margin: 0; position: relative;}
.email-subs-form{ position:relative;}
.email-subs-form form{  align-items: center;    justify-content: center;    display: flex; }
.email-subs-form input {
width: 100%;
height: 65px;
padding: 0px 175px 0px 30px;
margin: 0px 0 0px 0;
color: #172b43;
font-size: 16px;
outline: none;
background: #fff;
border: 1px solid #e6e6e6;
-webkit-border-radius: 100px;
border-radius: 100px;
-webkit-box-shadow: -6px 6px 11px #f2f2f2, 6px -6px 11px #fff0e8;
box-shadow: -6px 6px 11px #f2f2f2, 6px -6px 11px #fff0e8;}
.email-subs-form button {
width: max-content;
position: absolute;
right: 5px;
border-radius: 28px 100px 100px 28px;
padding: 0 30px;
line-height: 55px;
box-shadow: none;}
.footer-row2 h5 {margin-bottom: 25px;   font-size: 24px;  font-weight: 500;  margin-top: 30px;}
.footer-address-list li p{
font-weight: 600;
font-size: 18px;
font-family: 'Poppins', sans-serif;
line-height: 25px;}
.footer-address-list li p span{
display: flex;
font-weight: 400;
font-size: 15px;
font-family: 'Open Sans', sans-serif;}
.footer-address-list li > span {position: relative;   top: 5px;  }
.footer-address-list li { color: #6a6a8e;  display: flex;  padding: 0 0 5px 0; }
.footer-address-list li:last-child{ padding: 0 0 0px 0; }
.footer-address-list li a{color: #6a6a8e;
font-family: 'Open Sans', sans-serif;
font-size: 16px;
line-height: 24px;}
.footer-address-list li i{ margin: 0 15px 0 0;   display: initial; font-size: 21px;}
.ftr-details li{padding: 0 0 15px 0;}
.footer-block{  padding:20px 0;}
.social-links {text-align: right;}
.social-links a{ color: #6a6a8e;    font-size: 25px;    line-height: 1;}
.footer-social-media-icons a:hover{color:#ff1f8e !important}
.footer-social-media-icons a{
color: #6a6a8e;
font-size: 26px;
border-radius: 4px;
margin: 0 20px;
width: 35px;
height: 35px;
display: inline-block;
text-align: center;
line-height: 34px;}
.copyright{  text-align: center; color: #6a6a8e;}
.footer-social-media-icons{margin: 0 0 30px 0;}
.footer- p a{color:#6a6a8e;}
.link-hover a {
color: #6a6a8e;
font-family: 'Poppins', sans-serif;
display: inline-block;
padding: 3px 0;
position: relative;
overflow: hidden;
-webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
-o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
z-index: 1;}
.link-hover a::after {
content: "";
width: 0;
height: 1px;
bottom: 0;
position: absolute;
left: auto;
right: 0;
z-index: -1;
-webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
-o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
background: currentColor;}
.link-hover a:hover::after {width: 100%;left: 0;right: auto;}
.link-hover a:hover{    color: #ff1f8e !important;}
/**breadcrumb**/
.breadcrumb-area {padding:150px 0px 90px 0px;     position:relative; overflow:hidden; text-align:center;}
.breadcrumb-areav2 {
padding: 150px 0px 100px 0px;
position: relative;
overflow: hidden;
background-size: cover;
background-position: center center;
background-repeat: no-repeat;}
.bread-titlev2{text-align: center;}
.statistics-wrap{
padding: 30px 0;
-webkit-box-shadow:  0 10px 10px -10px rgba(0, 0, 0, 0.05);
box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.05);}
.breadcrumb-areav2:before{
content: '';   position: absolute;   top: 0;   height: 100%;   width: 100%;   opacity: .85;     right: 0;    left: 0; z-index:0;
background: -moz-linear-gradient(-45deg, #ffdee9 0%, #c8fff4  100%);
background: -webkit-linear-gradient(-45deg, #ffdee9 0%,#c8fff4  100%);
background: linear-gradient(135deg, #ffdee9 0%,#c8fff4  100%);}
.breadcrumb-area:before{
content: '';   position: absolute;   top: 0;   height: 100%;   width: 100%;   opacity: .85;     right: 0;    left: 0; z-index:0;
background: -moz-linear-gradient(-45deg, #ffdee9 0%, #c8fff4  100%);
background: -webkit-linear-gradient(-45deg, #ffdee9 0%,#c8fff4  100%);
background: linear-gradient(135deg, #ffdee9 0%,#c8fff4  100%);}
.breadcrumb-area:after{
content: ''; z-index:0;
background: url(../images/banner/banner-shap-2.png);
background-size: auto;   position: absolute;   height: 100%;   width: 100%;   background-repeat: no-repeat;
right: 0;   top: 0;   z-index: 1;   background-position: center right; background-size: contain;}
.breadcrumb-area ul li {    display: block;    display: inline-block;}
.breadcrumb-area .bread-menu li a {    color: #6a6a8e;    font-size: 16px;    font-weight: 400;    padding-right: 6px;  display: inline-block;}
.breadcrumb-area .bread-menu li + li::before {
font-size: 5px;
font-family: 'Font Awesome 5 Free';
content: "\f111";
font-weight: bold;
margin: 0 10px 0 0;
position: relative;
top: -3px;}
/***About page***/
.text-block{position:relative; z-index:55;}
.bread-title h2{font-weight: 600;  font-size: 35px; line-height: 47px; margin: 15px 0 0 0;}
.bread-inner p{font-size:16px; line-height:28px;}
.block-1 p + p{margin: 25px 0 0 0;}
.block-1 .small {   margin-top: 50px;}
.block-1 .statnumb {margin: 0 0 0 0px;}
.why-choose{background: #f7fcff;}
/**Team page**/
.banner-4{background: url(../images/banner/2.jpg);   background-repeat: no-repeat;    background-size: cover; background-position: center center;}
.team{overflow:hidden;}
.bg-gradient99{
background: #ffffff;
background: -moz-linear-gradient(top, #ffffff 0%, #eef4fd 100%);
background: -webkit-linear-gradient(top, #ffffff 0%,#eef4fd 100%);
background: linear-gradient(to bottom, #ffffff 0%,#eef4fd 100%);}
.image-div{
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
-webkit-border-radius: 8px; border-radius: 8px;    overflow: hidden;}
.info-text-block{padding: 20px 0 0px 0;}
.info-text-block h4{  margin: 0 0 10px 0;}
.info-text-block h4 a{color: #050748;}
.full-image-card{margin-top:60px;}
.otherinfo{    margin: 30px 0 0 0px;}
.otherinfo p{    line-height: 28px;}
.social-media-profile{    margin: 30px 0 0 0px;}
.social-media-profile a{
color: #6a6a8e;
font-size: 22px;
margin: 0 30px 0 0;
display: inline-block;
line-height: 34px;}
.social-media-profile a:hover{color:#ff1f8e ;}
.bg-shape img{position: relative; z-index: 5;}
.bg-shape:after{
content: '';
background: url(../images/shape/shape-1.svg);
background-size: auto;
position: absolute;
height: 150px;
width: 150px;
background-repeat: no-repeat;
left: -55px;
top: -55px;
z-index: 0;}
.bg-shape:before{
content: '';
background: url(../images/shape/shape-2.svg);
background-size: auto;
position: absolute;
height: 150px;
width: 150px;
background-repeat: no-repeat;
right: -55px;
bottom: -55px;
z-index: 0;}
/**Portfolio page**/
.banner-3{background: url(../images/banner/5.jpg);   background-repeat: no-repeat;    background-size: cover; background-position: center center;}
.common-heading.pp h2 { margin-bottom:0px;}
.common-heading.pp{ padding: 0;    text-align: left;}
.common-heading.pp p{    margin: 20px 0 0 0;}
.single-card-item{ margin-top: 60px;}
.filter-menu{text-align:right;}
.filters .filter-menu.recent {
list-style: none;
padding: 0;
bottom: 0;
position: inherit;
text-align: right;
margin: 35px 0 0 0;}
.filters .filter-menu li {
display: inline-block;
padding: 20px;
color: #333;
cursor: pointer;
background: #feeee3;
-webkit-border-radius: 100px;
border-radius: 40px 40px 40px 20px;}
.filters .filter-menu li.is-checked{background: #f95b94;   color: #fff;}
.portfolio-meta ul li {
font-size: 14px;
color: #fff;
display: block;
text-align: left;
background: #fff;
position: relative;
padding: 15px 20px 15px 55px;
border-bottom: 1px solid #ebebeb;}
.portfolio-meta ul li p {
font-family: 'Poppins', sans-serif;
font-size: 16px;
line-height: 28px;
background: #fff;
display: inline-block;
color: #050748;
font-weight: 600;}
.portfolio-meta ul li p span {
font-family: 'Open Sans', sans-serif;
display: block;
margin-top: 2px;
color: #6a6a8e;
font-weight: 400;}
.portfolio-meta ul li i {
position: absolute;
left: 10px;
color: #050748;
font-size: 25px;
top: 50%;
margin-top: -12.5px;}
.pv-:hover .item-image img {transform: scale(1.0);}
/***Service page***/
.h-scroll{
width: 100%;
padding: 50px 10px;}
.tech-icons img {
width: 45px;
margin: 0 auto;
position: absolute;
right: 0;
left: 0;
top: 50%;
transform: translatey(-50%);}
.tech-icons li {
margin: 6px;
display: inline-block;}
.tech-icons a:hover{border: 4px solid #d9cbff;}
.tech-icons a {border: 4px solid #fff;
display:inline-block;
height: 100%;
position: relative;
width: 90px;
height: 90px;
background: #fff;
border-radius: 100%;
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.techonology-used-{
text-align: center;
background: #f9f9f9;}
.banner-2{background: url(../images/banner/6.jpg);   background-repeat: no-repeat;    background-size: cover; background-position: center center;}
.service{overflow:hidden;}
.service .common-heading h2 {  margin-bottom: 20px;}
.cta-area{
position: relative;
overflow: hidden;
background: #e0f8ff;
background: -moz-linear-gradient(top, #e0f8ff 0%, #fff0e5 100%);
background: -webkit-linear-gradient(top, #e0f8ff 0%,#fff0e5 100%);
background: linear-gradient(to bottom, #e0f8ff 0%,#fff0e5 100%);}
.btn-outline{
border: 2px solid #000248;
color: #050748;
display: inline-block;
padding: 0px 32px;
font-family: 'Poppins', sans-serif;
font-size: 16px;
background: #fff;
-webkit-box-shadow: 0 10px 15px 0px rgba(3, 35, 68, 0.1);
box-shadow: 0 10px 15px 0px rgba(3, 35, 68, 0.1);
line-height: 52px;
-webkit-border-radius: 100px;
border-radius: 100px;}
.btn-outline:hover{ background: #ff1f8e;    color: #fff;    border: 2px solid #ff1f8e;}
.cta-call{ margin: 30px 0 0 0;}
.cta-call i{ margin: 0 5px 0 0px;}
.cta-call a{font-family: 'Poppins', sans-serif; font-size:24px; color: #6a6a8e;    display: inline-block;    padding: 0 0 0 10px;}
.cta-area .common-heading h2 {  margin-bottom: 40px;}
.shape{position: absolute;}
.shape-a1 { bottom: -20px;  left: 0;}
.shape-a2 { bottom: -30px;  right: 0;}
.shape-a3 { top:0px;     right: 0;}
.shape-a4 { top: -150px;    right: 20%;}
/**Service details page**/
.common-heading.ptag h2 {  margin-bottom: 15px;}
.common-heading.ptag p {  font-size: 16px;  line-height: 28px; }
.ps-block span{
font-size: 60px;
font-family: 'Poppins', sans-serif;
border-radius: 100%;
border: 1px solid #e8e8e8;
width: 100px;
height: 100px;
display: block;
text-align: center;
line-height: 100px;
margin: 0 0 20px 0;
color: #6a6a8e;
border-radius: 68% 32% 64% 36% / 59% 59% 41% 41%;
background: #f5f5ff;
animation: border-transform 6s linear infinite;}
.ps-block h3{margin-bottom: 20px;}
.ps-block p{ font-size: 16px; line-height: 28px;}
.ps-block{ padding: 0 25px;}
.featured-project .isotope_item {   margin-top: 60px;}
.featured-project{
background: #f3fbff;
background: -moz-linear-gradient(top, #f3fbff 0%, #fffdf6 100%);
background: -webkit-linear-gradient(top, #f3fbff 0%,#fffdf6 100%);
background: linear-gradient(to bottom, #f3fbff 0%,#fffdf6 100%);}
.maga-btn{text-align: center;}
.image-block1 img{
-webkit-box-shadow: -20px 20px 35px #dcdcdc, 20px -40px 35px #ffffff;
box-shadow: -20px 20px 35px #dcdcdc, 20px -40px 35px #ffffff;
-webkit-border-radius: 8px;
border-radius: 8px;
width:100%;}
.image-block img{
-webkit-box-shadow: -20px 20px 35px #dcdcdc, 20px -40px 35px #ffffff;
box-shadow: -20px 20px 35px #dcdcdc, 20px -40px 35px #ffffff;
-webkit-border-radius: 8px;}
.wide-sblock{
display: inline-flex;
text-align: left;
padding: 30px 20px;}
.s-block-content{    margin: 0 0 0 20px;}
.key-points{display:block;}
.key-points li{ padding: 8px 0 8px 35px;
position: relative;
line-height: 28px;
font-size: 17px;}
.key-points li:before{
content: "\f00c";
position: absolute;
font-family: "Font Awesome 5 Free";
font-weight: 900;
left: 0;
top: 9px;
background: #ffc627;
height: 25px;
width: 25px;
border-radius: 50%;
text-align: center;
align-items: center;
color: #333;}
.service-point-2{display: flow-root;}
.service-point-2 li{
font-size: 17px;
font-weight: 600;
line-height: 25px;
padding: 4px 0;
float: left;
width: 50%;}
.s-card-icon-large{width:150px;}
.s-block-content-large {margin: 0 0 0 20px; width: calc(100% - 150px); }
/**Pricing page**/
.pricing-table {
position: relative;
text-align: center;
padding: 30px 30px 0;
-webkit-border-radius: 8px;
border-radius: 8px;
-webkit-box-shadow: -30px 30px 60px #f2f2f2, 30px -30px 60px #ffffff;
box-shadow: -30px 30px 60px #f2f2f2, 30px -30px 60px #ffffff;}
.pricing-table img{
background: #e9f8ff;
width: 90px;
height: 90px;
border-radius: 100%;
padding: 10px;
border: 8px solid #fbfbfb;
animation: border-transform 6s linear infinite;}
.pricing-table .title {
margin: 30px 0 10px 0;
font-size: 16px;
font-family: 'Poppins', sans-serif;
font-weight: bold;
text-transform: uppercase;
width: 100%;
display: block;
letter-spacing: 0.5px;}
.pricing-table .title-sub{  margin:0 0 30px 0;}
.pricing-table .inner-table h2 {font-size: 42px;  font-weight: 700;   color: #ff1f8e;  margin-bottom: 3px;}
.pricing-table .inner-table p.duration {color: #72729a;  font-weight: 500; margin-bottom: 0;}
.pricing-table .inner-table .details {
margin-top: 35px;
padding-top: 28px;
padding-bottom: 30px;
line-height: 42px;
border-top: 1px solid #e5e5e5;}
.pricing-table a { margin-bottom: -26px;  display: inline-block;}
.pricing-table .inner-table .details ul {list-style: none;  margin-bottom: 0;  padding: 0;}
.pricing-table.best-plan .inner-table h2 { color: #2a89ea; }
.pricing-table.best-plan img{
background: #ccf0ff;
width: 90px;
height: 90px;
border-radius: 100%;
padding: 10px;
border: 8px solid #e9f8ff;}
/**Case study page**/
.banner-5{background: url(../images/banner/7.jpg);   background-repeat: no-repeat;    background-size: cover; background-position: center center;}
.case-study1{overflow:hidden;}
.case-study3{overflow:hidden;}
.case-study .image-div{
border-radius: 8px;
overflow:hidden;
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.case-study2{background: #fee;}
.image-div img{width:100%;}
.case-study2    .s-block {
text-align: center;
background: #fff;
padding: 50px 20px;
margin: 5px 0;
position: relative;
border: 1px solid #d4f0ff;
-webkit-box-shadow: -25px 25px 45px #f0dede, 25px -25px 45px #fff6f6;
box-shadow: -25px 25px 45px #f0dede, 25px -25px 45px #fff6f6;}
.case-study3 .image-block img, .case-study1 .image-block img{
-webkit-box-shadow: -20px 20px 35px #dcdcdc, 20px -40px 35px #ffffff;
box-shadow: -20px 20px 35px #dcdcdc, 20px -40px 35px #ffffff;
width: 100%;}
.case-study4{background: #fff;}
.project-platform{display: inline-flex;}
.project-platform-used a{
display: flex;
margin: 0 auto;
align-items: center;
height: 100%;}
.project-platform-used img{    width: 40px;
margin: 0 auto;}
.project-platform-used {
width: 80px;
height: 80px;
background: #fff;
border-radius: 100%;
text-align: center;}
.screen-splits{padding: 0 10px;}
/**Blog page**/
.vrbloglist + .vrbloglist{margin-top: 60px;}
.entry-blog {text-transform: capitalize;   font-weight: 400; font-size: 15px; margin: 5px 0 15px 0;}
.entry-blog a {  color: #6a6a8e;}
.entry-blog a i{margin-right:4px;}
.bypost{margin-right:10px;}
.item-image{position: relative;}
.category-blog{ position: absolute;    left: 0;    bottom: 0;}
.posted-on{margin-right:10px;}
.category-blog a{display: inline-block;  padding: 5px;  background: #fe409c;  color: #fff;}
.image-set{ border-radius:8px; overflow:hidden;}
.image-set img{width:100%;}
.blog-header .user-image img {   border-radius: 100%;  width: 50px;}
.blog-header .user-info {   font-family: 'Poppins', sans-serif;   margin: 0px 0 0 20px;}
.postwatch{text-align: right;}
.ul-list{padding: 0 0 0 20px;}
.ul-list li{font-size: 16px;   line-height: 38px;   list-style-type: circle;}
.rd-quote {padding: 30px;position: relative; border-left: 4px solid #fc4899; font-size: 20px; line-height: 32px; font-style: italic; margin: 40px 0; background: #FAFAFB;}
.author-block{position: relative;  padding: 30px;  margin-top: 50px;  border-top: none;  background: #fafafb; border-radius: 10px;}
.author-block .user-image img{width:80px;   border-radius:100px;}
.comments-block h2{ font-weight: 600;   font-size: 25px;  line-height: 35px;}
.comments-block  .user-info h5 span{font-size: 15px;  text-align: right;  float: right; color: #6a6a8e;}
.comments-block .user-info h5 { font-size: 20px;  display: flow-root;}
.comments-block .user-info h5 a{display: inline-block;  padding: 0 0 0 6px;  color: #00b2ff;}
.comments-block .user-image img{width:50px; border-radius:100px;}
.replied{padding-left:60px;}
.form-blog  button{ width:max-content;}
.comments-block .media + .media{margin-top: 60px;}
.form-blog { background: #fafafb;  padding: 40px; border-radius: 8px;}
.offer-image{    border-radius: 8px;    overflow: hidden;}
.offer-image img{   width:100%;}
.recent-post .post-info p {    font-size: 16px;    line-height: 28px;}
.recent-post .post-image img {   border-radius:8px;   width: 80px;}
.recent-post .post-image a{ display:block;}
.recent-post .post-info h5 a{   color: #050748;}
.recent-post .post-info h5 a:hover{ color: #2196f3;}
.recent-post .post-info h5 {    font-size: 16px;    line-height: 26px;}
.post-info {    margin: 0px 0 0 20px;}
.widgets h3{font-size:20px;}
.recent-post .media + .media{margin-top: 20px;}
.tabs a{
font-size: 15px;
font-weight: 400;
text-transform: lowercase;
padding: 0px 10px;
line-height: 30px;
border-radius: 4px;
border: none;
color: inherit;
margin: 0 4px 8px 0;
display: inline-block;
background: #F6F5FA;
font-family: 'Poppins', sans-serif;}
.blog-categories ul li:first-child a{padding-top: 0;}
.blog-categories ul li a{
font-family: 'Poppins', sans-serif;
display: block;
padding: 10px 0;
font-size: 16px;
line-height: 28px;
border-bottom: 1px solid #D3D3D3;
text-decoration: none;
color: #757589;
position: relative;}
.blog-categories ul li span.categories-number {   position: absolute;   right: 0;}
.blog-categories ul li:first-child {  border-top: 0px solid;}
.blog-post-tag span {
font-size: 18px;
font-weight: 700;
display: block;
margin-bottom: 17px;
color: rgb(6, 42, 77);}
.blog-share-icon span {
font-size: 18px;
font-weight: 700;
color: rgb(6, 42, 77);
display: block;
margin-bottom: 17px;}
.blog-share-icon a { color: #6a6a8e;   font-size: 18px;   margin-right: 22px; }
.blog-post-tag a {
color: #6a6a8e;
display: inline-block;
font-size: 14px;
font-weight: 600;
margin-right: 10px;
margin-bottom: 10px;
border-width: 2px;
border-style: solid;
border-color: rgb(234, 237, 255);
border-image: initial;
padding: 12px 24px;
border-radius: 5px;}
.blog-share-icon a:last-child {   margin-right: 0px;}
.post-navigation span a {color: #6a6a8e;   margin-bottom: 5px;  display: block;}
.post-navigation h4 a{ color: #050748;}
/**contact page**/
.banner-6{background: url(../images/banner/4.jpg);   background-repeat: no-repeat;    background-size: cover; background-position: center center;}
.contact-location{overflow:hidden;}
.info-card { padding: 30px;
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.contact-card{background: #fff8f2;}
.email-card{background: #ffecec;}
.skype-card{background: #eafffd;}
.info-card span i{    margin-right: 10px;}
.info-card span{ font-size: 20px;   font-weight: 700; font-family: 'Poppins', sans-serif;}
.info-card p{font-size: 16px; line-height: 28px; margin: 20px 0 10px 0; }
.info-card a{font-size: 20px;  color: inherit;  font-weight: 600;}
.bglight{    background: #fbfbfb;}
/**list**/
.list-ul li{padding: 0 0px 12px 29px; position: relative; line-height: 22px; font-size: 16px;}
.list-ul li:before{
font-family: "Font Awesome 5 Free";
font-weight: bold;
position: absolute;
left: 0;
top: 2px;
font-size: 15px; }
.ul-check li:before{  content: "\f00c";}
.ul-circle li:before{ content: "\f0a9";}
.ul-numm li{    list-style-type: decimal;    padding: 0 0px 12px 5px;}
.ul-abc li{    list-style-type: upper-latin;    padding: 0 0px 12px 5px;}
.ul-liin {padding: 0 0 0 20px;}
/**404 page**/
.error{   height: 100vh;}
.error-block{   padding-top: 50px; position:relative;    z-index: 5;}
.layer-div{ position:relative;}
.error-block a{margin-top:40px; }
.layer-div:before {
background: url(../images/icons/404.svg);
background-repeat: no-repeat;
background-size: 70%;
background-position: center center;
content: "";
display: flex;
align-items: center;
justify-content: center;
position: absolute;
top: 0;   bottom: 0;   text-align: center;   right: 0;   left: 0;  z-index: 0;}
/**Faq & accordion page**/
.card-2 .card-header button:hover{color: #050748;}
.card-2 .card-header {
padding: 10px;border: 0;}
.card-2 .card-header button{
border: 0;
padding: 0;
color: #050748;
font-size: 18px;
font-weight: 600;}
.accordion .acc-icon:after{
font-family: "Font Awesome 5 Free";
content: "\f068";
font-weight: bold;
position: absolute;
font-size: 14px;
right: 5px;
top: 5px;
color: #050748;}
.accordion .acc-icon.collapsed:after {   content: "\f067";   font-weight: bold; }
.accordion .card-title:after {
font-family: "Font Awesome 5 Free";
content: "\f068";
font-weight: bold;
position: absolute;
font-size: 14px;
right: 5px;
top: 2px;
background: #00baff;
width: 25px;
height: 25px;
line-height: 25px;
text-align: center;
border-radius: 100px;
color: #fff;}
.accordion .card-title.collapsed:after {   content: "\f067";   font-weight: bold; }
.card-header .btn-link:hover {   color: #6a6a8e;   text-decoration: none;}
.card-header .btn-link.focus, .btn-link:focus {   text-decoration: none;   box-shadow: none;}
.accordion .card-body {   -ms-flex: 1 1 auto;   flex: 1 1 auto;   min-height: 1px;   padding: 0px 15px 15px 15px;}
.panel .panel-collapse .collapse {   transition: transform .25s ease-in-out;   -webkit-transition: -webkit-transform .25s ease-in-out;}
.card-1 .card-header {
padding:10px 15px;
margin-bottom: 0;
background-color: transparent;
border-bottom: 0;
position: relative;
cursor: pointer;}
.card-1 {
border: 1px solid #d4f0ff;
overflow: hidden;
-webkit-border-radius: 8px;
border-radius: 8px;
margin-bottom: 20px;
padding:0px;}
.card-1 .card-title {
margin-bottom: 0;
font-weight: 600;
font-size: 16px;
line-height: 26px;
color: #6a6a8e;
padding:2px 40px 2px 0}
.tabs-layout .nav-tabs .nav-link.active { color: #ffffff; background-color: #00baff; border-color: #00baff; border-radius: 100px;}
.tabs-layout .nav-tabs .nav-link {
border: 0px solid transparent;
color: #6a6a8e;
background-color: #efefef;
border-radius: 100px;
padding:10px 35px;}
.tabs-layout .nav-tabs .nav-item  + .nav-item{ margin:0 10px;}
.tabs-layout .nav-tabs .nav-item  + .nav-item:last-child{  margin:0px;}
.tabs-layout .nav-tabs {   border-bottom: 0px solid #dee2e6;}
.tabs-layout .tab-content{  padding: 20px 0 0px 0;}
.outline-tabs.tabs-layout .nav-tabs .nav-link {  border: 1px solid #efefef;  color: #6a6a8e;  background-color: #ffffff;  border-radius: 100px;}
.outline-tabs.tabs-layout .nav-tabs .nav-link.active {  color: #ffffff;  background-color: #00baff;  border-color: #00baff;  border-radius: 100px;}
/**Client reviews**/
.reviews-block{background: #fafaff;}
.pr-shadow{
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.revbx-lr {font-size: 40px; }
.revbx-rl {text-align: right; display: block; }
.revbx-rl img{      max-width: 130px;}
.reviews-card {position: relative; z-index: 9; background: #fff; padding: 25px;     border-radius: 8px;}
.right-review-box p {
color: #676767;
font-size: 16px;
line-height: 25px;
letter-spacing: 0.4px;
font-style: inherit;}
.review-text{padding: 30px 0;}
.left-review-box h4 {
font-size: 16px;
letter-spacing: 1px;
color: #333;
padding: 8px 0 0 0;}
.-client-details-{display: flex;}
.-reviewr{
width: 70px;
height: 70px;
border-radius: 100px;
overflow: hidden;
margin-right: 15px;}
.reviewer-text h4 {color: #333; padding: 5px 0 0 0; font-size: 16px; line-height: 24px;}
.review-text {text-align: justify;}
.reviewer-text p {font-size: 15px; line-height: 20px; }
.star-rate li {display: inline-block; margin: 0 2px;}
.star-rate{margin-top:5px;}
.star-rate li a.chked{color: #ff711c;}
.star-rate li a{    color: #6a6a8e;}
/**Freelance Homepage**/
.fl-hero{
background: url(../images/hero/freelancer.png), url(../images/shape/hero-shape.svg),#ffd6d6;
background-position: center center, center center;
background-size: cover, cover;
background-repeat: no-repeat, no-repeat;}
.showin-mobile{display:none;}
.follow-label{display: flex;}
.follow-label h6{ margin: 0 40px 0 0px;   position: relative;}
.follow-label h6:after{
content: '';
position: absolute;
top: 50%;
right: -38px;
width: 30px;
height: 1px;
background: #6a6a8e;}
.follow-label a{color: #6a6a8e;    padding: 5px 10px;    font-size: 20px;}
.bhv-service{overflow:hidden;}
.counter-number{transform: translateZ(20px);}
.hexagon {
transform-style: preserve-3d;
transform: perspective(1000px);
text-align: center;
min-width: 240px;
height: auto;
padding: 20px 30px;
margin: 0px 20px;
background: #fff;
border-radius: 8px 40px 8px 40px;
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.hexa1{  border: 2px solid #ffc3fe;}
.hexa2{  border: 2px solid #ffe6ba;}
.hexa3{  border: 2px solid #addaff;}
.hexa4{  border: 2px solid #ffbaba;}
.counter-no span{
font-size: 50px;
font-weight: 700;
color: #050748;}
.counter-no p {
font-size: 20px;
margin: 10px 0 0 0;
font-weight: 500;}
.counter-no{    display: inline-block;}
.freelance-service{  background: #d4f0ff;}
.header-heading .title-{display: block; margin-bottom: 15px;}
.header-heading span{color: #e60072;}
/**Lead Generation Homepage**/
.lead-hero{
background: url(../images/hero/bg-gradient.jpg);
background-position: center center;
background-size: cover;
background-repeat: no-repeat;}
.follow-label{display: flex;}
.lead-gen-client .clients-logo {
background: #ffffff;
padding: 0px 15px;
max-height: 150px;
min-height: 150px;
margin: 0 0px;
border: 1px solid #d4f0ff;
align-items: center;
display: flex;
position: relative;
border-radius: 8px;
-webkit-box-shadow: -20px 20px 40px #ece0f2, 20px -20px 40px #fff8ff;
box-shadow: -20px 20px 40px #ece0f2, 20px -20px 40px #fff8ff;}
.itm-media-object .media .img-media{
width: 65px;
background: #d3f3ff;
padding: 10px;
border-radius: 8px;}
.itm-media-object .media-body{margin-left: 30px;}
.why-choos-lg{position:relative;}
.why-choos-lg:before{
content: "";
background: url(../images/shape/dot_bg.png);
position: absolute;
width: 100%;
height: 100%;
top: 0;}
.itm-media-object h4{text-align:left;}
/**Digital agency homepage**/
.srvc-bg-nx{
background: #fff;
overflow: hidden;
border-radius: 6px;
margin: 0 10px;
height: 240px;
-webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.about-sec-rpb{background: #f2f2f2;}
.about-sec-rpb.pad-tb{padding-bottom: 200px;}
.badges-content{text-align: center;}
.badges-content p{
font-size: 15px;
line-height: 22px;
padding: 0 50px;
margin-top: 10px;}
.w-txt h2{ color:#fff; }
.common-heading.w-txt > span{color:#fff;}
.-service-list li:first-child {padding-left: 0;}
.-service-list li{
text-transform: uppercase;
list-style: none;
padding: 0 8px;
border-right: 1px solid #d4d4d4;
display: inline-block;}
.-service-list li:last-child {border-right: none;}
.service-sec-list p {
font-size: 15px;
line-height: 24px;
margin: 14px 0 0 0;}
.-service-list li a{
font-size: 13px;
color: #6a6a8e;
text-transform: uppercase;
text-decoration: none;
padding-bottom: 5px;
display: inline-block;
font-weight: 700;}
.link-prb{display: block;}
.col-lg-6-cus{width:40%;}
.col-lg-3-cus{width:30%;}
.mt30-{margin-top: 20px;}
.text-w, .text-w h4, .text-w a, .text-w h1, .text-w h2, .text-w h3, .text-w h5, .text-w h6{color:#fff;}
.service-section-prb{position: relative; margin-top: -200px;}
.bg-shape-dez{position: relative;}
.bg-shape-dez::after, .bg-shape-dez::before{
position: absolute;
z-index: 0;
content: '';
width: 50%;
height: 50%;
background: #007bff;
background: -webkit-linear-gradient(left, #007bff 0%,#ff1f8e 100%);
background: linear-gradient(to right, #007bff 0%,#ff1f8e 100%);}
.bg-shape-dez::after{ top: -10px;   right: -10px; border-radius: 0px 8px 0px 0px;}
.single-image img{  position: relative;   z-index: 5;   border-radius: 8px;}
.bg-shape-dez::before{   bottom: -10px;   left: -10px; border-radius: 0px 0px 0px 8px;}
.single-image img{ position: relative;   z-index: 5;   border-radius: 8px;}
.srcl1{background: #f3f5ff; border-bottom: 4px solid #b0bdff;}
.srcl2{background: #fff3e6; border-bottom: 4px solid #ffc093;}
.srcl3{background: #fbeefd; border-bottom: 4px solid #f6bcff;}
.srcl4{background: #effdff; border-bottom: 4px solid #9ff3ff;}
.srcl5{background: #fbfff1; border-bottom: 4px solid #ffdaa2;}
.service-sec-list p{display:none;}
.service-sec-list{ padding: 52px 25px;}
.service-sec-brp{ padding:30px 25px;}
.service-sec-list img{
-moz-transition: width 0.5s, height 0.5s;
-webkit-transition: width 0.5s, height 0.5s;
transition: width 0.5s, height 0.5s;
height: 60px;
width: 60px;
margin-bottom: 10px;}
.service-sec-list:hover{padding: 25px; }
.service-sec-list:hover img{height:0px;  width: 60px;}
.service-sec-list:hover p {display: block;}
.service-sec-list:hover p, .service-sec-list:hover, .service-sec-list{
-moz-transition: all 0.8s;
-o-transition: all 0.8s;
-webkit-transition: all 0.8s;
transition: all 0.8s;}
.-cta-btn {
margin: 0 auto;
display: inline-block;
text-align: center;
width: 100%;}
.-cta-btn p{font-size: 30px; color: #050748; margin: 0 30px 0 0px;}
.-cta-btn p span{    font-weight: bold;}
.free-cta-title{    display: inline-flex;}
.key-block.shadow-box {
padding: 15px;
margin-bottom: 20px;
border-top: 3px solid #a241bb;
transition: all .3s ease-out 0s;}
.key-icon {margin-right: 15px;float: left;}
.clients-logos ul li p {
font-size: 12px;
padding-top: 0.5em;
letter-spacing: 0px;
font-weight: 300;
color: #959595;}
.clients-logos ul li {display: inline-block; padding: 30px 0 0 0;}
.brand-logo{    max-width: 180px; margin: 0 auto; padding: 10px;}
.video-review{border-radius: 8px; overflow: hidden;}
.video-review a{position: relative; display: inline-block;}
.video-review a:after{ content: ""; display: block;
position: absolute;
left: 0;
top: 0;
opacity: 1;
width: 100%;
height: 100%;
z-index: 5;
background: -moz-linear-gradient(top, rgb(30,87,153, 0) 0%, rgb(32,124,202, 0) 70%, rgb(0, 0, 0, 0.5) 100%);
background: -webkit-linear-gradient(top, rgb(30,87,153, 0) 0%,rgb(32,124,202, 0) 70%,rgb(0, 0, 0, 0.5) 100%);
background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 0%,rgba(32, 124, 202, 0) 70%,rgba(0, 0, 0, 0.5) 100%); }
.review-vid-details{
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
padding: 10px;
position: absolute;
bottom: 0;
z-index: 10;}
.review-vid-details p{font-weight: bold; color: #fff; font-size: 20px;}
.-vid-ico{
min-width: 40px;
min-height: 40px;
display: inline-flex;
background: #ffffff;
border-radius: 1000px;
transition: all 0.3s ease-in-out 0s;
cursor: pointer;
outline: none;
position: relative;
padding: 10px;
align-items: center;}
.triangle-play2 {
width: 0;
height: 0;
border-top: 7px solid transparent;
border-left: 12px solid #ff2d8d;
border-bottom: 7px solid transparent;
text-align: center;
margin: 0 auto 0;}
.rount-btn{
width: 52px;
height: 52px;
padding: 0;
text-align: center;
font-size: 18px;
border: 2px solid #dcdcdc;}
.review-ref{ display: flex; align-items: center; justify-content: space-around;}
.review-icons a{display: inline-block;
padding: 0 20px;}
.bg-none{background: none;}
/**Digital Agency home 2**/
.img-with-shape{position: relative;
text-align: center;}
.dg-hero-img0{
border-radius: 8px;
transform: translateZ(60px);
position: relative;
z-index: 9;}
.dg-hero-shp1a{top: 0px;left: 0px;}
.dg-hero-shp1{top: -50px;left: 0px;}
.dg-hero-shp2{transform: translatez(80px);top: 40%;z-index: 999;left: 0px;max-width: 120px;}
.dg-hero-shp3{bottom: -80px;
right: 0px;}
.shape-dg-1{position: absolute;}
.dark-main  p, .dark-main .-content-sec .title-hero-oth p, .dark-main .footer-address-list li a, .dark-main .post-social a, .dark-main a {color:#a2a2a2}
.dark-main h2,.dark-main h3,.dark-main h4,.dark-main h5,.dark-main h6, .dark-main .title-hero-oth p, .dark-main .statistics span, .dark-main .single-blog-info- h4 a,    .dark-main .review-vid-details p, .dark-main .isotope_item h4 a, .dark-main .link-reviewrr a, .dark-main .entry-blog-post a, .dark-main h1 {color: #e6e6e6;}
.dark-main a.lnk, .dark-main a.bg-btn{color: #fff;}
.dark-main input, .dark-main textarea{box-shadow: none;}
.dark-main .s-block {background: #1d1d1d; border: 1px solid #1d1d1d;}
.dark-main .single-blog-post- {background: #1d1d1d;  border: 1px solid #1d1d1d;}
.dark-main .post-social { border-top: 1px solid #2b2b2b;}
.dark-main .blog-content-tt {    border: 0px solid #ececec;}
.f-weight .-cta-btn p span {font-weight: 900;}
p.video-lab--{    color: #6a6a8e;}
.mr25{margin-right: 25px}
.dg-bg2{
background-image: -moz-linear-gradient(45deg,#ff3870 0%,#ff3870 50%,#c300c3 100%) !important;
background-image: -webkit-linear-gradient(45deg,#ff3870 0%,#ff3870 50%,#c300c3 100%) !important;
background-image: linear-gradient(45deg,#ff0048 0%,#ff3870 50%,#c300c3 100%) !important; }
.hero-digital-agency-1 p{color:#bdbfca}
.hero-digital-agency-1 .header-heading h1 {font-size: 80px; line-height: 90px; font-weight: 900; }
.-content-sec .triangle-play {border-left: 25px solid #ffffff; }
.-content-sec a.play-video::after { border: 6px solid #ff2d8d;}
.-content-sec a.play-video { background: #ff2d8d;}
.-content-sec .title-hero-oth p {font-size: 18px; color: #6a6a8e;margin: 0 0 0 20px;text-transform: capitalize;}
.dg-btn{background-color: transparent; border: 1px solid #fff;}
.dg-bg{background-color: #202020;}
.dg-bg--1 {  background-color: #111111;}
.dg-bg--2 {  background-color: #171717;}
.dg-clients-section .opl ul li {display: inline-block; padding: 30px 0 0 0;}
.dg-clients-section .opl ul li p {
font-size: 12px;
padding-top: 0.5em;
letter-spacing: 0px;
font-weight: 300;
color: #959595;}
.dg-testinomial-section .owl-carousel .owl-dots {
position: absolute;
bottom: 0;
right: 0;
text-align: right;
background: transparent;
width: 100px;
margin: 0;
height: 50px;
padding: 25px 0 0 0;}
.quote-css{font-size: 45px; padding: 0 20px 0 0; color: #909090;}
.video-testimonial{ display: flex; }
.dg-statistics-section .statistics span {font-size: 45px; font-weight: 900;}
.common-heading-2 {
padding: 0;
text-align: center;
position: relative;
z-index: 10;}
.common-heading-2 h2{font-weight: 900; }
.common-heading-2 > span {
color: #e60072;
text-transform: uppercase;
letter-spacing: 0.5px;
font-size: 16px;
font-weight: 400;
margin: 0 0 15px 0;
display: block;
font-weight: 900;}
.itm-media-object .media img {width: 100%; }
.itm-media-object .media .img-ab-{
background: #f5f5f5;
border-radius: 8px;
width: 65px;
padding: 10px;}
.overlay-- .s-block{
background: #222;
border: 1px solid #222;
padding: 30px;
overflow: hidden;}
.ovr-bg1 .ovr-base:before {
content: "";
background: -webkit-linear-gradient(45deg,#ff0048 0%,#ff3870 50%,#c300c3 100%);
background: linear-gradient(45deg,#ff0048 0%,#ff3870 50%,#c300c3 100%);
width: 100%;
height: 100%;
position: absolute;
top: 0;
right: 0;
left: 0;
opacity: 0;}
.nn-card-set{position: relative; z-index: 5; overflow: hidden;}
.ho-gdnt .s-block:hover:before {opacity: 1;}
.ho-gdnt .s-block{overflow: hidden;}
.ho-gdnt .ovr-base:before{
-webkit-transition: 0.8s cubic-bezier(.17, .85, .438, .99);
-o-transition: 0.8s cubic-bezier(.17, .85, .438, .99);
transition: 0.8s cubic-bezier(.17, .85, .438, .99); }
.ho-gdnt  .s-block:hover  p, .ho-gdnt .s-block:hover a, .ho-gdnt .s-block:hover h4 {color: #ffffff;}
.dg2-main .isotope_item h4 a {  color: #fff;}
.up-hor:hover{transform: translateY(-7.5px);}
.h-scl- .h-scl-base{
-webkit-transform: scale(1);
-ms-transform: scale(1);
transform: scale(1);}
.h-scl-:hover .h-scl-base {
-webkit-transform: scale(.9);
-ms-transform: scale(.9);
transform: scale(.9);}
.h-scl-:hover .h-scl-base img {
-webkit-transform: scale(1.15);
-ms-transform: scale(1.15);
transform: scale(1.15);}
.h-scl-  .h-scl-base {
-webkit-transform: scale(1);
-ms-transform: scale(1);
transform: scale(1);}
.h-scl- .h-scl-base,  .h-scl-base img, .up-hor, .bd-hor .bd-hor-base:after {
-webkit-transition: 1.2s cubic-bezier(.17, .85, .438, .99);
-o-transition: 1.2s cubic-bezier(.17, .85, .438, .99);
transition: 1.2s cubic-bezier(.17, .85, .438, .99);}
.itm-media-object h4 {
text-align: left;
margin-bottom: 10px;}
.single-blog-post-{
border-radius: 8px;
background: #fff;}
.single-blog-img- img{    border-radius: 8px 8px 0px 0px;}
.single-blog-img-{position: relative;}
.entry-blog-post{
border-radius: 100px;
padding: 10px 15px;
margin: 0 20px;
position: absolute;
bottom: -18px;
right: 0;
left: 0;
justify-content: space-between;
display: flex;}
.entry-blog-post a{ color: #fff;   font-size: 14px;}
.single-blog-info- h4{margin-bottom: 18px;}
.single-blog-info- h4 a{ color: #050748; }
.post-social{
margin: 30px 0 0 0;
border-top: 1px solid #dadada;
padding-top: 30px;}
.post-social a{
color: #6a6a8e;
display: inline-block;
padding: 0 10px 0 0;}
.blog-content-tt{
padding: 45px 25px 25px;
border: 1px solid #ececec;
border-top: 0;
border-radius: 0px 0px 8px 8px;}
.link-reviewrr{ margin: 30px 0 0 0;}
.link-reviewrr a{
display: block;
padding: 0 0 4px 0;
color: #6a6a8e;}
.dark-footer,.dark-footer .footer-address-list li a,.dark-footer .footer-address-list li > span,.dark-footer .footer-address-list li p, .dark-footer .ff-social-icons a, .dark-footer .footer-link-- li a{color: #a2a2a2;}
.dark-footer h5{color:#e6e6e6;}
.dg2-main .footer-address-list li a {color: #bdbfca;}
.footer-copyrights- a{color: #bdbfca;}
.footer-link--{text-align: right;}
.footer-link-- li { display: inline-block; }
.footer-link-- li a{ color: #bdbfca; margin-left: 15px; font-size: 16px; }
.end-footer-{
border-top: 1px solid rgba(237,237,237,0.05);
margin-top: 70px;
padding: 35px 0;}
.ff-social-icons a{
color: #fff; padding: 0 20px 0 0; font-size: 18px;}
.video-bg-{background: #000;}
.full-video {
position: absolute;
right: 0;
bottom: 0;
width: 100%;
height: 100%;
object-fit: cover;
object-position: center;
opacity: 0.3;}
.email-subs-form .form-cta{
align-items: center;
justify-content: center;
display: flex;}
/**creative Agency home 1**/
.hero-creative-agenc1{
background-size: cover;
background-position:  center center;}
.hero-creative-agenc1 .header-heading h1 {font-weight: 900;}
.ovr-bg2 .ovr-base:before {
content: "";
background: -webkit-linear-gradient(135deg, #fc00c7 0%,#1c4efd 54%,#00aded 100%);
background: linear-gradient(135deg, #fc00c7 0%,#1c4efd 54%,#00aded 100%);
width: 100%;
height: 100%;
position: absolute;
top: 0;
right: 0;
left: 0;
opacity: 0;}
.sec-dark h2, .sec-dark h4, .sec-dark h5 {color:#fff;}
.sec-dark p {color:#bdbfca;}
/**creative-agenc 2**/
.hero-creative-agenc2 .container {  max-width: 1320px;}
.img-design{display: inline-flex; align-items: center;}
.ag-dg1{        z-index: 1;    border-radius: 8px 8px 8px 100px;    min-width: 100%;    top: 0;    left: 30px;    position: relative;}
.ag-dg2{z-index: 0;    border-radius: 8px 8px 100px 8px;    bottom: 0;    right: 30px;    min-width: 100%;    position: relative;}
.shpdzz{position: absolute;}
.shpdez1{width: 90px;    height: 90px;    background: #FFC107;    border-radius: 100px 100px 00px 100px;    left: 0;    bottom: 0;}
.shpdez2{width: 140px;    height: 140px;    background: #00BCD4;    border-radius: 00px 100px 100px 100px;    left: 0;    top: 0px;    z-index: 5;}
.shpdez3{width: 40px;    height: 40px;    background: #4caf50;    border-radius: 100px 0px 100px 100px;    left: 160px;    top: 0px;}
.shpdez4{ width: 140px;    height: 80px;    background: #ff6c89;    border-radius: 100px;    left: 130px;    bottom: 0px;    z-index: 5;}
.img-block-ca2 .abt-ca2 {border-radius: 100px 00px 100px 0px;    position: relative;    z-index: 5;    width: 80%;}
.img-block-ca2 .dot-sp-ca2{position: relative;z-index: 0}
.div-portfolio-{background: #1d1d1d;    padding: 60px;    border-radius: 8px;}
.itm-tag{   font-weight: 700;    font-style: italic;}
.div-item-image img{border-radius: 8px;    width: inherit !important;    margin: 0 auto;}
.porto-slide .owl-dots{text-align: center;    margin-top: 30px;}
.img-ca2set{max-height: 370px; overflow: hidden;border-radius: 8px;}
.aa a:hover{color:#ff1f8e !important ;}
.bd-hor .bd-hor-base{overflow: hidden;}
.bd-hor .bd-hor-base:hover:after {    width: 100%;    overflow: hidden;}
.bd-hor .bd-hor-base:after {
content: '';
position: absolute;
left: 0;
width: 0%;
bottom: 0;
height: 4px;
background: -webkit-linear-gradient(-45deg, #fc00c7 0%,#1c4efd 54%,#00aded 100%);
background: linear-gradient(135deg, #9100ff 0%,#3663ff 54%,#00e2ff 100%);}
.dot-c1{
position: absolute;
width: 80px;
height: 80px;
border-radius: 100px;
border: 0;}
.dot-c1 img{ width: 70px; border-radius: 100px;}
.tcc1 {background: #ffc107; width: 130px; text-align: left; padding-left: 5px; border-radius: 100px 100px 100px 100px;}
.tcc2 {background: #f44336; right: 0; width: 120px; text-align: right; padding-right: 5px; border-radius: 0px 100px 100px 0px; }
.tcc3 {background: #00bcd4; right: 120px; width: 130px; top: 200px; border-radius: 100px 8px 100px 100px; text-align: left; padding-left: 5px; }
.tcc4 {
background: #ff6c89;
left: 150px;
top: 110px;
width: 150px;
border-radius: 100px 100px 100px 8px;
text-align: right; padding-right: 5px; }
.tcc5 {background: #4caf50; right: 0px; bottom: 0px; border-radius: 100px 100px 100px 8px; width: 130px; text-align: right; padding-right: 5px; }
.tcc6 { background: #9C27B0; bottom: 0; left: 10%; width: 150px; text-align: left; padding-left: 5px; border-radius: 100px 8px 100px 100px; }
.dot-c1.active { box-shadow: 6px 6px 0px rgb(255 255 255 / .5); }
.instagram-post .thumb{overflow: hidden;} 
.instagram-post{display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px;}
/**minimal-portfolio**/
.minimal-portfolio{background-repeat: no-repeat; background-position: center center; background-size: contain;}
.minimal-portfolio .dzname { font-size: 22px; line-height: 30px; font-weight: 700; }
h3 a{color: #050748;}
.br-blog-post-{position: relative;}
.link-blog-post a{position: absolute; right: 0; bottom: 0; padding: 20px;  background: #fff; color: #050748; font-weight: 600; }
.btn-flnc{  align-items: flex-end;    display: flex;}
.post-newsltr .owl-stage{padding-left:0px !important;}
.bg-light-f7{background: #f7f7f7;}
.img-hro img{border-radius: 8px; }
/**mobile-app-**/
.mobile-app-{
background-image: -moz-linear-gradient(45deg,#9217de 0%,#4775ff 50%,#00b6d4 100%);
background-image: -webkit-linear-gradient(45deg,#9217de 0%,#4775ff 50%,#00b6d4 100%);
background-image: linear-gradient(45deg,#4a0079 0%,#4202b2 50%,#4400b1 100%);}
.hero-heading-sec2 h1 {
color: #fff;
font-size: 55px;
line-height: 67px;
font-weight: 900;
display: inline-block;
background: linear-gradient( 90deg,#af6dff 0%,#ff448e 25%,#ff9d57 50%,#ff38d3 75%,#00ffdc 100% );
background-size: cover;
background-position: center;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
color: transparent;}
.hero-heading-sec2 > p {color: #fff000; font-size: 18px; margin: 40px 0; }
.wide-sblock {display: inline-flex; text-align: left; padding: 30px 20px; }
.img100w {max-width: 100% !important; width: auto !important;}
.funfct {
box-shadow: 0 11px 20px rgb(123 149 162 / 15%);
line-height: 26px;
padding: 30px;
border-radius: 8px 8px 8px 40px;
margin-top: 30px; position: relative; text-align: center; }
.funfct p{font-weight: 600; color: #6a6a8e;}
.funfct img{width: 65px; margin: 0 auto 15px; display: block;}
.funfct .services-cuntr{ display: inline-block; font-weight: 900; font-size: 30px; color: #050748; padding: 15px 0; }
.about-sec-app{
background: url(../images/shape/bg-light2.png);
background-size:contain;
background-repeat: repeat-x;
background-position: center center;}
.service-section-app{
background: url(../images/shape/wave-bg.png);
background-size:contain;
background-repeat: repeat-x;
background-position: center bottom;
position: relative; }
.-service-list li:first-child {padding-left: 0;}
.-service-list li {
text-transform: uppercase;
list-style: none;
padding: 0 8px;
border-right: 1px solid #d4d4d4;
display: inline-block;
}
.link-prbs {display: block; color: #6a6a8e;  font-size: 16px; font-family: 'Poppins', sans-serif; }
.tec-icon ul li .img-iconbb { display: inline-flex; border-radius: 100%; background: #f5f7ff; width: 60px; height: 60px; align-items: center; }
.tec-icon ul li img {width: 40px; margin: 0 auto; }
.tec-icon ul li { display: inline-block; margin: 0 10px 0 0; }
.service-card-app{
display: block;
background: #fff;
box-shadow: 0 11px 20px rgb(123 149 162 / 15%);
font-weight: 400; font-size: 14px; padding: 30px; border-radius: 40px 8px 40px 8px; }
.servc-icon-sldr{display: flex;}
.clients-section-app{    background: #4302ae;}
.item-info-div{background: #fff; padding: 15px; position: relative; top: -40px; width: 95%; border-radius: 40px 8px 8px 8px; margin: 0 auto; text-align: center; }
.item-info-div h4{margin: 0;}
.industry-workfor{ background: #fff; padding: 15px 20px; border-radius: 8px; margin-top: 30px; display: flex; align-items: center; }
.hoshd:hover { box-shadow: 0 15px 39px -11px #46638e45; transform: translate(0px, -5px); }
.industry-workfor img{width: 55px;}
.industry-workfor h6{    margin: 0 0 0 15px;}
.tcd{display: block; background: #fff; box-shadow: 0 11px 20px rgb(123 149 162 / 15%); font-weight: 400; font-size: 14px; padding: 60px 30px; border-radius: 40px 8px 8px  8px;}
.posiqut { position: absolute; right: 10px; bottom: 10px; font-size: 60px; color: #00f2a6;}
.pl50 { padding-left: 50px !important;}
.tcd  .owl-dots { position: absolute; bottom: 0; right: 0; text-align: right; margin: 15px; }
.app-cta{background: #4301b2; position: relative; overflow: hidden; }
.shape-c1 {background: transparent; width: 200px; height: 200px; border-radius: 100px 100px 100px 100px; border: 6px solid #f8d873; }
.shape-c2 {background: transparent; width: 200px; height: 200px; border-radius: 100px 100px 100px 30px; border: 6px solid #00f2a6; right: -20px; top: -70px; }
.shape-c3 { background: transparent; width: 100px; height: 100px; border-radius: 100px 100px 100px 30px; border: 6px solid #fff; right: 90px; bottom: -20px; }
.apppg .brand-logo {max-width: 180px; margin: 0 auto; padding: 10px; background: #fff; border-radius: 8px; }
.apppg li p{    color: #fff; margin: 10px 0 0 0;}
.info-text-div { padding: 20px; background: #fff; box-shadow: 0px 20px 30px -20px rgb(0 21 167 / 20%); border-radius: 8px 8px 8px 8px; border-bottom: 5px solid #b0bdff; }
.landscp{    text-align: center;background: #fbfbfb;}
.sctxt{
font-size: 90px;
position: absolute;
right: 0; left: 0; text-align: center; top: 0; font-weight: 900; text-transform: uppercase; -webkit-text-stroke: 1px #e8e8e8; -webkit-text-fill-color: white;}
/**business-startup**/
.business-startup{ background: url(../images/hero/wave-hero.svg); background-repeat: no-repeat; background-position: center top;}
.bg-light-ylo { background: #e9f5ff; }
.up-curvs{position: absolute; right: 0; left: 0; top: -50px; }
.bottom-curvs{position: absolute; right: 0; left: 0; bottom: -50px; }
.bg-flat5 {   background: #fff9f1;}
.bg-flat6 {   background: #fff3f3;}
.login-form-div{ max-width: 460px; margin: 0 auto; background: #fff; padding: 40px; border-radius: 8px;}
.login-page {background:url(../images/shape/bg-rectangles-.png), #101010; display: flex; height: 100vh; }
.fb-btn {line-height: 46px;  background-color: #3b5998!important;}
.google-btn {line-height: 46px;  background-color: #e94235!important;}
.closes {right: 8px; font-size: 35px;  font-weight: 700; top: -2px; color: #d6d6d6; border: 0; background: transparent; }
.popup-modals .modal-dialog {max-width: 600px; }
.modal-body {position: relative; -ms-flex: 1 1 auto; flex: 1 1 auto; padding: 30px; }



/**start update 1.7 from here**/
/*shop page*/

@media (min-width: 1200px){
.shop-container{max-width: 1110px !important}
}

.shop-tags-list li {
    display: inline-block;
    margin-top: 10px;
    margin-right: 2px;
    margin-left: 2px;
}
.shop-tags-list li a{
    display: inline-block;
    background-color: #ffffff;
    border: 1px solid #f2effc;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 225, 0.08);
    color: #050748;
    padding: 12px 16px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
}
.shop-tags-list li a.active{
	background-color: #436ad6;
    color: #fff;
}
.shop-tags-list{text-align: center;}
.shop-tags-list li a span{
	    display: inline-block;
    margin: 0 0 0 4px;
}
.main-shop-catte a{
display: inline-block;
    background-color: #e2e2ef;
    border: 1px solid #f2effc;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 225, 0.08);
    color: #050748;
    padding: 18px 18px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
}
.main-shop-catte a.active{
	background-color: #436ad6;
    color: #fff;
}
.main-shop-catte {
    text-align: center;
}
.shop-products-bhv{
	background: #fafafa;
}
.rpb-shop-items-dv{
	padding: 0;
	margin: 0;
	border: 0;
	text-align: inherit;
}
.rpb-shop-items-dv a{
	padding: 0;margin: 0;
}
.rpb-shop-items-img{}
.rpb-shop-items-info{padding: 16px;}
.rpb-shop-items-tittl h3{
	font-size: 16px;
    line-height: 1;
}
.rpb-shop-items-tittl h3 a{
	    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 24px;
}
.rpb-shop-items-tittl p.tags-itmm{
	font-size: 14px;
    line-height: 22px;
    margin: 0;
    padding: 0;
    color: #6a6a8e;
}

.rpb-shop-items-flex{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.rpb-shop-inf-ll{}
.rpb-itm-pric span.regular-prz{
	font-size: 20px;
    font-weight: 100;
    color: #6a6a8e;
    text-decoration: line-through;
    margin: 0 0 0 6px;	    
}
.rpb-itm-pric span.offer-prz{
	   font-size: 20px;
    font-weight: 700;
    color: #050748;
}
.rpb-tim-rate ul li a{
	margin: 0;
	padding: 0;
	font-size: 13px;
}
.rpb-tim-rate ul li{margin: 0;}
.rpb-tim-rate{margin-top: 5px;}
.rpb-itm-sale{
    margin-top: 10px;
    display: block;
    font-size: 14px;}

.rpb-shop-inf-rr{
	align-items: flex-end;
    display: flex;
}    
.rpb-shop-inf-rr a.rpb-shop-prev{
	display: inline-block;
    background-color: #ffffff;
    border: 1px solid #f2effc;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 225, 0.08);
    color: #050748;
    padding: 12px 16px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
}
.pagination {
    display: -ms-flexbox;
    display: flow-root;
    list-style: none;
    border-radius: .25rem;
    margin: 60px 0 0 0;
    text-align: center;
}
.pagination a{
  display: inline-block;
  padding: 10px 18px;
  color: #222;
}
.pagi1 a{
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-align: center;
}

.pagi1 a.is-active{
  background-color: #436ad6;
  border-radius: 100%;
  color: #fff;
}

/*shop details*/

.rpb-shop-prevw{
	margin-bottom: 40px;
    background-color: #f6f6f7;
    padding: 20px;
}
/*tab css*/
.tab-17 .nav-tabs{
	background: #f6f6f7;
    padding: 0px 15px;
}

.tab-17.tabs-layout .nav-tabs .nav-link.active {
    color: #6a6a8e;
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
    border-bottom: 2px solid #ff358d;
}
.tab-17.tabs-layout .nav-tabs .nav-link {
    border: 0px solid transparent;
    color: #6a6a8e;
    background-color: transparent;
    border-radius: 0;
    padding: 15px;
    border-bottom: 2px solid #f6f6f7;
}
.badges {
    display: inline-block;
    padding: .25em .4em;
    font-size: 50%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.rpb-item-review .reviews-card {
    position: relative;
    z-index: 9;
    background: #f6f6f7;
    padding: 25px;
    border-radius: 8px;
    margin-bottom: 20px;
}
.rpb-item-infodv{
	    margin-bottom: 40px;
    background-color: #f6f6f7;
    padding: 20px;
}
.nx-rt{
    float: right;
    font-size: 15px;
    width: 60%;
    text-align: right;}

.rpb-item-infodv ul li strong {
color: #050748;
    font-size: 15px;
    float: left;
    width: 40%;
}
.rpb-item-infodv ul li + li {
    border-top: 1px solid #e0e0e0;  
}

.rpb-item-infodv ul li {
    padding: 15px 0;
        display: flow-root;
}
.btn-grp a{	 margin: 0 10px;}

/*cart page*/
.div-for-data {
border: 1px solid #e0e0e0;
    margin: 0;
    text-align: left;
    width: 100%;
    border-radius: 8px;
    background: #fff;
        border-collapse: separate;
}
.rpb-cart-table table.cart_table th {
    padding: 15px 20px;
    color: #050748;
    font-size: 17px;
}

.rpb-cart-table table.cart_table td {
border-top: 1px solid #e0e0e0;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #050748;
}
.rpb-cart-table table .product-thumbnail img {
    width: 60px;
}

.rpb-cart-table table .product-name a {
   color: #050748;
}
.rpb-cart-table a.remove {
display: block;
    font-size: 24px;
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 100%;
    color: #ff0000!important;
    text-decoration: none;
    font-weight: 700;
    border: 0;
    background: #efefef;
}
.update-cart{text-align: right;}
.cart-pg-coupon input{
	width: 180px;
    height: 45px;
    padding: 0px 10px;
    margin: 0px 0 0px 0;
    color: #172b43;
    font-size: 16px;
    outline: none;
    background: #fff;
    border: 1px solid #e6e6e6;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}
button.smllbtnn{ 
	line-height: 45px;
    color: #fff;
    border-radius: 8px;
    padding: 0 20px;
    border: 0;}


.cart-extra-sevc{padding: 20px;}

.post-info h5 a{
	color: #050748;
    font-size: 16px;
}
.mt5{margin-top: 5px;}

table {
    color: #050748;
}
table.border{border-radius: 8px;}
.btn-small {
    font-size: 15px;
    font-weight: 400;  
    padding: 0px 20px;
    line-height: 35px;  
}
.media + .media {    margin-top: 20px;}

/*contact page 2*/
.map-div iframe{width: 100%; height: 200px; border: 0;}
.contact2dv{
	background: #e7fbff;
    padding: 30px;
}
.info-wrapr .dbox p span {
    color: #6a6a8e;
    font-size: 20px;
    display: block;
    text-transform: capitalize;
    font-weight: 700;
}

.info-wrapr .dbox {
    width: 100%;
     color: #6a6a8e;
    margin-bottom: 25px;
}

.info-wrapr .icon i{
	    font-size: 25px;
}
.info-wrapr .text a{
	 color: #6a6a8e;
}
.pr30{padding-right: 30px;}
.pl30{padding-left: 30px;}
.addtocart{
	    width: 20px;
    height: 20px;
    background: #fafafa;
    display: block;
    position: absolute;
    top: 1px;
    right: -6px;
    line-height: 20px;
    border-radius: 100%;
    color: #050748;
    box-shadow: 0px 5px 10px rgb(93 0 39 / 44%);
    font-size: 14px;
    font-weight: bold;
}
/**end update 1.7 from here**/

/**Features**/
.custom-file-input:focus~.custom-file-label {  border-color: #ced4da;  box-shadow: none;}
.dropcap{    float: left;   font-size: 45px;   line-height: 1;   padding-right: 10px;   text-transform: uppercase;}
.list-style-{padding: 0 0 0 20px;text-align: left;}
.list-style- li{line-height: 32px;
list-style-type: circle;}
.img-ctr{    text-align: center;
display: block;}
.footer-link-v2 li{display: inline-flex;    padding: 0px 15px;}
.footer-badges-{    display: flex;
justify-content: space-between;}
.footer-badges- li{    display: inline-block;}
.footer-badges- li a img {width: 150px; }
.footer-brands{background: #f8f9fa; padding: 30px 0;}
.single-blog- img {height: 70px; width: 70px; display: block; border-radius: 8px;}
.single-blog- .post-meta i {margin: 0 5px 0 0; }
.single-blog- .post-meta {font-size: 13px; }
.single-blog- .content .title{line-height: 20px; font-size: 15px;}
.single-blog- .content .title a {color: #050748; }
.single-blog- .content .title a:hover {color: #ff1f8e ; }
.post-thumb{margin-right: 15px;}
.single-blog-{    display: inline-flex;}
.footer-blog- .single-blog-:last-child{margin-top: 20px;}
.icon-card-{  text-align: center;
background: #fff;
border: 1px solid #eaeaea;
border-radius: 8px;
padding: 15px;}
.icon-card- img{width:60px;}
.icon-card- h4{margin: 20px 0}
/**Help css**/
.onloadpage {position: fixed; z-index: 99999999; top: 0; right: 0; bottom: 0; left: 0; display: block; background: #ffffff;}
.onloadpage .pre-content{top: 50%; position: relative; right: 0; left: 0; text-align: center; transform: translateY(-50%);   }
.onloadpage .pre-text- {  font-size: 18px;    line-height: 28px;    font-weight: 600;    width: 30%;    margin: 0 auto;}
.onloadpage .pre-text- span{display: block;}
.logo-pre {
background: #e2e2e2;
border-radius: 50%;
height: 100px;
width: 100px;
box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);;
animation: pulse 2s infinite;
margin: 0 auto 30px;
display: flex;
align-items: center;
padding: 8px;}
@keyframes pulse {
0% {box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);}
70% {box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);}
100% {box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); }}
.tilt-3d .base {transform-style: preserve-3d;    transform: perspective(1000px);}
.tilt-3d .layer{transform: translateZ(20px);}
.shdo{    -webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);}
.-shadow{
-webkit-box-shadow: -20px 20px 35px #dcdcdc, 20px -40px 35px #ffffff;
box-shadow: -20px 20px 35px #dcdcdc, 20px -40px 35px #ffffff;}
a.disabled { pointer-events: none; cursor: default; opacity: 0.4;}
h1 {  font-size: 45px;  line-height: 57px;}
h2 {  font-size: 40px;  line-height: 52px;}
h3 {  font-size: 34px;  line-height: 46px;}
h4 {  font-size: 22px;  line-height: 32px;}
h5 {  font-size: 20px;  line-height: 30px;}
h6 {  font-size: 16px;  line-height: 26px;}
p {   font-size: 17px;  line-height: 28px;}
.pad-tb { padding-top: 100px; padding-bottom: 100px;}
.pr--100 { padding-right: 100px;}
.pl--100 { padding-left: 100px;}
.pl--50 { padding-left: 50px;}
.pr--50 { padding-right: 50px;}
.mr15{margin-right: 15px;}
html .pl5{padding-left:5px !important;}
html .pt0{padding-top:0;}
html .pm0{padding-bottom:0;}
html .mt0{margin-top: 0px;}
html .mb0{margin-bottom: 0;}
html .mt10{margin-top: 10px;}
html .ml10{margin-left: 10px;}
html .mt20{margin-top: 20px;}
html .mt30{margin-top: 30px;}
html .mt40{margin-top: 40px;}
html .mt50{margin-top: 50px;}
html .mt60{margin-top: 60px;}
html .mt70{margin-top: 70px;}
html .mt80{margin-top: 80px;}
html .mt90{margin-top: 90px;}
html .mt100{margin-top: 100px;}
html .mb10{margin-bottom: 10px;}
html .mb20{margin-bottom: 20px;}
html .mb25{margin-bottom: 25px;}
html .mb30{margin-bottom: 30px;}
html .mb40{margin-bottom: 40px;}
html .mb50{margin-bottom: 50px;}
html .mb60{margin-bottom: 60px;}
html .mb70{margin-bottom: 70px;}
html .mb80{margin-bottom: 80px;}
html .mb90{margin-bottom: 90px;}
html .mb100{margin-bottom: 100px;}
html .m0{margin: 0px !important;}
html .p0{padding: 0px !important;}
html .pt10{padding-top: 10px;}
html .pt20{padding-top: 20px;}
html .pt30{padding-top: 30px;}
html .pt40{padding-top: 40px;}
html .pt50{padding-top: 50px;}
html .pt60{padding-top: 60px;}
html .pt70{padding-top: 70px;}
html .pt80{padding-top: 80px;}
html .pt90{padding-top: 90px;}
html .pt100{padding-top: 100px;}
html .pt110{padding-top: 110px;}
html .pt120{padding-top: 120px;}
html .pt130{padding-top: 130px;}
html .pt140{padding-top: 140px;}
html .pb10{padding-bottom: 10px;}
html .pb20{padding-bottom: 20px;}
html .pb30{padding-bottom: 30px;}
html .pb40{padding-bottom: 40px;}
html .pb50{padding-bottom: 50px;}
html .pb60{padding-bottom: 60px;}
html .pb70{padding-bottom: 70px;}
html .pb80{padding-bottom: 80px;}
html .pb90{padding-bottom: 90px;}
html .pb100{padding-bottom: 100px;}
html .pb110{padding-bottom: 110px;}
html .pb120{padding-bottom: 120px;}
html .pb130{padding-bottom: 130px;}
html .pb140{padding-bottom: 140px;}
.badge-warning {    color: #212529;    background-color: #ffc107;    text-align: center;    display: inline;}
.xhighlight{ display: block;  color: #ff2c8e;}
.pl25{padding-left: 25px !important}
.pr25{padding-right: 25px !important}
.text-effect-1 {
background-image: -moz-linear-gradient(45deg,#ff3870 0%,#ff3870 50%,#c300c3 100%);
background-image: -webkit-linear-gradient(45deg,#ff3870 0%,#ff3870 50%,#c300c3 100%);
background-image: linear-gradient(45deg,#ff3870 0%,#ff3870 50%,#c300c3 100%);
-webkit-background-clip: text;
-moz-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;}
.text-effect-2 {
background: #fc00c7;
background-image: -moz-linear-gradient(-45deg, #fc00c7 0%, #1c4efd 54%, #00aded 100%);
background-image: -webkit-linear-gradient(-45deg, #fc00c7 0%,#1c4efd 54%,#00aded 100%);
background-image: linear-gradient(135deg, #fc00c7 0%,#1c4efd 54%,#00aded 100%);
-webkit-background-clip: text;
-moz-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;}
.bg-btn{
background: #ffbd84;
background: -moz-linear-gradient(left, #ffbd84 0%, #ff1f8e 100%);
background: -webkit-linear-gradient(left, #ffbd84 0%,#ff1f8e 100%);
background: linear-gradient(to right, #ffbd84 0%,#ff1f8e 100%);
-webkit-box-shadow: 0 10px 15px 0px rgba(175, 0, 87, .2);
box-shadow: 0 10px 15px 0px rgba(175, 0, 87, .2);}
.bg-btn2{
background: #f92c8b;
background: -moz-linear-gradient(left, #f92c8b 0%, #b02cd6 100%);
background: -webkit-linear-gradient(left, #f92c8b 0%,#b02cd6 100%);
background: linear-gradient(to right, #f92c8b 0%,#b02cd6 100%);
-webkit-box-shadow: 0 10px 15px 0px rgba(175, 0, 202, .2);
box-shadow: 0 10px 15px 0px rgba(175, 0, 202, .2);}
.bg-btn3 {
background: #673AB7;
background: -moz-linear-gradient(left, #673AB7 0%, #2196F3 100%);
background: -webkit-linear-gradient(left, #673AB7 0%,#2196F3 100%);
background: linear-gradient(to right, #673AB7 0%,#2196F3 100%);
-webkit-box-shadow: 0 10px 15px 0px rgba(56, 0, 189, .2);
box-shadow: 0 10px 15px 0px rgba(56, 0, 189, .2);}
.bg-btn4 {/*use in dark mode*/
background: transparent;
-webkit-box-shadow: none;
box-shadow: none;
border: 1px solid #fff;}
.bg-btn5 {
background: #ff0048;
background-image: -webkit-linear-gradient(45deg,#ff3870 0%,#ff3870 50%,#c300c3 100%);
background-image: linear-gradient(45deg,#ff0048 0%,#ff3870 50%,#c300c3 100%);
-webkit-box-shadow: 0 10px 15px 0px rgba(56, 0, 189, .2);
box-shadow: 0 10px 15px 0px rgba(56, 0, 189, .2);}
.bg-btn6 {
background: #fc00c7;
background: -moz-linear-gradient(-45deg, #fc00c7 0%, #1c4efd 54%, #00aded 100%);
background: -webkit-linear-gradient(-45deg, #fc00c7 0%,#1c4efd 54%,#00aded 100%);
background: linear-gradient(135deg, #fc00c7 0%,#1c4efd 54%,#00aded 100%);}
.bg-btn7 {
background: #fc00c7;
background: -moz-linear-gradient(-45deg, #fc00c7 0%, #1c4efd 54%, #00aded 100%);
background: -webkit-linear-gradient(-45deg, #fc00c7 0%,#1c4efd 54%,#00aded 100%);
background: linear-gradient(135deg, #9100ff 0%,#3663ff 54%,#00e2ff 100%);
-webkit-box-shadow: 0 10px 15px 0px rgba(56, 0, 189, .2);
box-shadow: 0 10px 15px 0px rgb(0 49 189 / .2);}
.hover-scale img{transform: scale(1.0);}
.hover-scale:hover img {transform: scale(1.05);}
.text-animation{
font-weight: bold;
color: transparent;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
-webkit-animation: hue 6s infinite linear; }
.bg-a{ background-image: linear-gradient(to right, #ff1f8e 0%,#ffbd84 36%,#ffbd84 65%,#ff1f8e 100%);}
.bg-b{ background-image: linear-gradient(to right, #673AB7 0%,#E91E63 36%,#E91E63 65%,#673AB7 100%);}
@keyframes slideIn {
0% {transform: translateY(1rem);    opacity: 0; }
100% {transform:translateY(0rem);   opacity: 1; }
0% {transform: translateY(1rem);    opacity: 0; } }
@-webkit-keyframes slideIn {
0% {-webkit-transform: transform;        -webkit-opacity: 0;    }
100% {-webkit-transform: translateY(0);  -webkit-opacity: 1;    }
0% {-webkit-transform: translateY(1rem); -webkit-opacity: 0;    } }
@keyframes hue {
from {background-position:0 0;  }
to {background-position: 2000px 0;} }
@keyframes arrowanim{
0%{ transform: translate(0, 0); }
50%{ transform: translate(8px, 0); }
100%{ transform: translate(0, 0); }
}
@-webkit-keyframes arrowanim{
0%{ transform: translate(0, 0); }
50%{ transform: translate(8px, 0); }
100%{ transform: translate(0, 0); }
}
@keyframes updonanim{
0%{ transform: translate(0, 0); }
50%{ transform: translate(0, 8px); }
100%{ transform: translate(0, 0); }
}
@-webkit-keyframes updonanim{
0%{ transform: translate(0, 0); }
50%{ transform: translate(0, 8px); }
100%{ transform: translate(0, 0); }
}
@keyframes border-transform{
0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; }
14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; }
28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; }
42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; }
56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; }
70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; }
84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; }
}
.lead-intro- img{ animation: 3s infinite updonanim; -webkit-animation: 3s infinite updonanim; }
/**Gredient css**/
.bg-gradient{
background: #c7ecff;
background: -moz-linear-gradient(top, #c7ecff 0%, #f9efff 100%);
background: -webkit-linear-gradient(top, #c7ecff 0%,#f9efff 100%);
background: linear-gradient(to bottom, #c7ecff 0%,#f9efff 100%);}
.bg-gradient1{
background: #ffe2e2;
background: -moz-linear-gradient(top, #ffe2e2 0%, #fff9f4 100%);
background: -webkit-linear-gradient(top, #ffe2e2 0%,#fff9f4 100%);
background: linear-gradient(to bottom, #ffe2e2 0%,#fff9f4 100%);}
.bg-gradient2{
background: #fdffed;
background: -moz-linear-gradient(top, #fdffed  0%, #effff8  100%);
background: -webkit-linear-gradient(top, #fdffed  0%,#effff8  100%);
background: linear-gradient(to bottom, #fdffed 0%,#effff8 100%);}
.bg-gradient3{
background: #e2f3ff;
background: -moz-linear-gradient(top, #e2f3ff  0%, #fffbef  100%);
background: -webkit-linear-gradient(top, #e2f3ff  0%,#fffbef  100%);
background: linear-gradient(to bottom, #e2f3ff  0%,#fffbef  100%);}
.bg-gradient4{
background: #c1eeff;
background: -moz-linear-gradient(top, #c1eeff 0%, #f5faff 100%);
background: -webkit-linear-gradient(top, #c1eeff 0%,#f5faff 100%);
background: linear-gradient(to bottom, #c1eeff 0%,#f5faff 100%);}
.bg-gradient5{
background: #fff6f3     ;
background: -moz-linear-gradient(top, #fff6f3 0%, #fffefb 100%);
background: -webkit-linear-gradient(top, #fff6f3 0%,#fffefb 100%);
background: linear-gradient(to bottom, #fff6f3 0%,#fffefb 100%);}
.bg-gradient6{
background: #e8fffc ;
background: -moz-linear-gradient(top, #e8fffc 0%, #fff4f4  100%);
background: -webkit-linear-gradient(top, #e8fffc 0%,#fff4f4  100%);
background: linear-gradient(to bottom, #e8fffc 0%,#fff4f4  100%);}
.bg-gradient7{
background: #ffe2e2;
background: -moz-linear-gradient(top, #ffe2e2 0%, #fff9f4 100%);
background: -webkit-linear-gradient(top, #ffe2e2 0%,#fff9f4 100%);
background: linear-gradient(to bottom, #ffe2e2 0%,#fff9f4 100%);}
.bg-gradient8{
background: #fff8fb;
background: -moz-linear-gradient(top, #fff8fb 0%, #d0fcf6 100%);
background: -webkit-linear-gradient(top, #fff8fb 0%,#d0fcf6 100%);
background: linear-gradient(to bottom, #fff8fb 0%,#d0fcf6 100%);}
.bg-gradient9{
background: #e5ffee;
background: -moz-linear-gradient(top, #ffe2e2 0%, #fff9f4 100%);
background: -webkit-linear-gradient(top, #ffe2e2 0%,#fff9f4 100%);
background: linear-gradient(to bottom, #e5ffee 0%,#e2f6ff 100%);}
.bg-gradient10{
background: #e4fffe;
background: -moz-linear-gradient(top, #e4fffe 0%, #ffedf3 100%);
background: -webkit-linear-gradient(top, #e4fffe 0%,#ffedf3 100%);
background: linear-gradient(to bottom, #e4fffe 0%,#ffedf3 100%);}
.bg-gradient11{
background: #ff1f8e;
background: -moz-linear-gradient(left, #ff1f8e 0%, #007bff 100%);
background: -webkit-linear-gradient(left, #ff1f8e 0%,#007bff 100%);
background: linear-gradient(to right, #ff1f8e 0%,#007bff 100%);}
.bg-gradient12{
background: #211e31;
background: -moz-linear-gradient(left, #211e31 0%, #211e31 100%);
background: -webkit-linear-gradient(left, #211e31 0%,#211e31 100%);
background: linear-gradient(to right, #211e31 0%,#211e31 100%);}
.bg-gradient13{
background: #9C27B0;
background: -moz-linear-gradient(left, #9C27B0 0%, #673AB7 100%);
background: -webkit-linear-gradient(left, #9C27B0 0%,#673AB7 100%);
background: linear-gradient(to right, #9C27B0 0%,#673AB7 100%);}
.bg-gradient14{
background: #ff2d8d;
background: -moz-linear-gradient(top, #ff2d8d 0%, #653db9  100%);
background: -webkit-linear-gradient(top, #ff2d8d 0%,#653db9  100%);
background: linear-gradient(to bottom, #ff2d8d 0%,#653db9  100%);}
.bg-gradient15{
background: #fffdf2;
background: -moz-linear-gradient(top, #fffdf2 0%, #ffeff5 100%);
background: -webkit-linear-gradient(top, #fffdf2 0%,#ffeff5 100%);
background: linear-gradient(to bottom, #fffdf2 0%,#ffeff5 100%);}
.bg-gradient16 {
background-image: -moz-linear-gradient(45deg,#9217de 0%,#4775ff 50%,#00b6d4 100%);
background-image: -webkit-linear-gradient(45deg,#9217de 0%,#4775ff 50%,#00b6d4 100%);
background-image: linear-gradient(45deg,#9217de 0%,#4775ff 50%,#00b6d4 100%);
}
.bg-flat1{background: #f5f5f5}
.classic-bg1{background: #fffef9;}
.classic-bg2{background: #f3fffe;}
.classic-bg3{background: #fef9ff;}
.classic-bg4{background: #3f36b9;}
.classic-bg5{background: #fffef4;}
/**Mobile Nav**/
.mobile-menu2{display: none;}
.mobile-menu2 .navm-{margin: 0px 0 0 10px;}
.mob-nav2 {display: flex;}
.mob-nav2 li{position: relative;}
html.hc-nav-yscroll {overflow-y: scroll}
body.hc-nav-open {overflow: visible;    position: relative;    width: 100%; min-height: 100%}
.hc-offcanvas-nav { visibility: hidden; display: none;  position: fixed;    top: 0; height: 100%;   z-index: 9999999;}
.hc-offcanvas-nav.is-ios * {    cursor: pointer !important}
.hc-offcanvas-nav .nav-container {  position: fixed;    z-index: 9998;  top: 0; height: 100%;   max-width: 100%;    max-height: 100%;
box-sizing: border-box; transition: transform .4s ease}
.hc-offcanvas-nav .nav-wrapper {    width: 100%;    height: 100%;   box-sizing: border-box; }
.hc-offcanvas-nav .nav-content { height: 100%}
.hc-offcanvas-nav .nav-wrapper-0>.nav-content {
overflow: scroll;
overflow-x: visible;
overflow-y: auto;
box-sizing: border-box}
.hc-offcanvas-nav ul {list-style: none; margin: 0; padding: 0}
.hc-offcanvas-nav li {position: relative; display: block }
.hc-offcanvas-nav li.level-open>.nav-wrapper {visibility: visible}
.hc-offcanvas-nav li:not(.custom-content) a {
position: relative;
display: block;
box-sizing: border-box;
cursor: pointer}
.hc-offcanvas-nav li:not(.custom-content) a[disabled] { cursor: not-allowed}
.hc-offcanvas-nav li:not(.custom-content) a,
.hc-offcanvas-nav li:not(.custom-content) a:hover { text-decoration: none }
.hc-offcanvas-nav input[type="checkbox"] { display: none }
.hc-offcanvas-nav label {position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 10; cursor: pointer }
.hc-offcanvas-nav .nav-item {position: relative; display: block; box-sizing: border-box }
.hc-offcanvas-nav.disable-body::after,
.hc-offcanvas-nav .nav-wrapper::after {
content: '';
position: fixed;
z-index: 9990;
top: 0;
left: 0;
right: 0;
bottom: 0;
width: 100%;
height: 100%;
visibility: hidden;
opacity: 0;
transition: visibility 0s ease .4s, opacity .4s ease}
.hc-offcanvas-nav.disable-body.nav-open::after,
.hc-offcanvas-nav .sub-level-open::after {visibility: visible; opacity: 1; transition-delay: .05s }
.hc-offcanvas-nav:not(.nav-open)::after {pointer-events: none }
.hc-offcanvas-nav.nav-levels-expand .nav-wrapper::after {display: none }
.hc-offcanvas-nav.nav-levels-expand .nav-wrapper.nav-wrapper-0 {max-height: 100vh }
.hc-offcanvas-nav.nav-levels-expand .nav-wrapper.nav-wrapper-0>.nav-content {
overflow: scroll;
overflow-x: visible;
overflow-y: auto;
box-sizing: border-box;
max-height: 100vh}
.hc-offcanvas-nav.nav-levels-expand ul .nav-wrapper {
min-width: 0;
max-height: 0;
visibility: hidden;
overflow: hidden;
transition: height 0s ease .4s}
.hc-offcanvas-nav.nav-levels-expand .level-open>.nav-wrapper {max-height: none; overflow: visible; visibility: visible }
.hc-offcanvas-nav.nav-levels-overlap .nav-content {
overflow: scroll;
overflow-x: visible;
overflow-y: auto;
box-sizing: border-box;
max-height: 100vh}
.hc-offcanvas-nav.nav-levels-overlap .nav-wrapper {max-height: 100vh}
.hc-offcanvas-nav.nav-levels-overlap ul .nav-wrapper {
position: absolute;
z-index: 9999;
top: 0;
height: 100%;
visibility: hidden;
transition: visibility 0s ease .4s, transform .4s ease}
.hc-offcanvas-nav.nav-levels-overlap ul li.nav-parent {position: static }
.hc-offcanvas-nav.nav-levels-overlap ul li.level-open>.nav-wrapper {
visibility: visible;
transform: translate3d(0, 0, 0);
transition: transform .4s ease}
.hc-offcanvas-nav.nav-position-left {left: 0}
.hc-offcanvas-nav.nav-position-left .nav-container {left: 0}
.hc-offcanvas-nav.nav-position-left.nav-levels-overlap li .nav-wrapper {left: 0; transform: translate3d(-100%, 0, 0)}
.hc-offcanvas-nav.nav-position-right {right: 0 }
.hc-offcanvas-nav.nav-position-right .nav-container {right: 0 }
.hc-offcanvas-nav.nav-position-right.nav-levels-overlap li .nav-wrapper {right: 0; transform: translate3d(100%, 0, 0)}
.hc-offcanvas-nav.nav-position-top {top: 0 }
.hc-offcanvas-nav.nav-position-top .nav-container {top: 0; width: 100%}
.hc-offcanvas-nav.nav-position-top.nav-levels-overlap li .nav-wrapper {left: 0; transform: translate3d(0, -100%, 0) }
.hc-offcanvas-nav.nav-position-bottom {top: auto; bottom: 0 }
.hc-offcanvas-nav.nav-position-bottom .nav-container {top: auto; bottom: 0; width: 100%}
.hc-offcanvas-nav.nav-position-bottom.nav-levels-overlap li .nav-wrapper {left: 0; transform: translate3d(0, 100%, 0) }
.hc-offcanvas-nav.nav-open[class*='hc-nav-'] div.nav-container {transform: translate3d(0, 0, 0) }
.hc-offcanvas-nav.rtl li {text-align: right}
.hc-offcanvas-nav.rtl .nav-next span,
.hc-offcanvas-nav.rtl .nav-back span,
.hc-offcanvas-nav.rtl .nav-close span {left: 0; right: unset}
.hc-nav-trigger {
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
display: none;
top: 20px;
z-index: 9980;
width: 30px;
min-height: 48px}
.hc-nav-trigger span {
width: 30px;
top: 50%;
transform: translateY(-50%);
transform-origin: 50% 50%}
.main-header.sticky .hc-nav-trigger span,
.main-header.sticky .hc-nav-trigger span::before,
.main-header.sticky .hc-nav-trigger span::after {
background: #050748;}
.hc-nav-trigger span,
.hc-nav-trigger span::before,
.hc-nav-trigger span::after {
display: block;
position: absolute;
left: 0;
height: 4px;
background: #050748;
transition: all .2s ease}
.nav-bg-b .hc-nav-trigger span,
.nav-bg-b .hc-nav-trigger span::before,
.nav-bg-b .hc-nav-trigger span::after {
background: #fff;}
.hc-nav-trigger span::before,
.hc-nav-trigger span::after {content: ''; width: 100% }
.hc-nav-trigger span::before {top: -10px }
.hc-nav-trigger span::after {bottom: -10px }
.hc-nav-trigger.toggle-open span {background: rgba(0, 0, 0, 0) !important; transform: rotate(45deg)}
.hc-nav-trigger.toggle-open span::before {transform: translate3d(0, 9px, 0);}
.hc-nav-trigger.toggle-open span::after {transform: rotate(-90deg) translate3d(10px, 0, 0) }
.hc-offcanvas-nav::after,
.hc-offcanvas-nav .nav-wrapper::after {background: rgba(0, 0, 0, 0.5)}
.hc-offcanvas-nav .nav-container,
.hc-offcanvas-nav .nav-wrapper,
.hc-offcanvas-nav ul {background: #101010}
.hc-offcanvas-nav h2 {    font-size: 22px;
font-weight: normal;
text-align: left;
padding: 10px 17px;
color: #101010 !important;
background: #fff;}
.hc-offcanvas-nav .nav-item {
padding: 14px 17px;
font-size: 15px;
color: #fff;
z-index: 1;
background: rgba(0, 0, 0, 0);
border-bottom: 0px solid #fff}
.hc-offcanvas-nav .nav-item:focus,
.hc-offcanvas-nav .nav-item:focus-within {z-index: 10 }
.hc-offcanvas-nav .nav-item-wrapper { position: relative }
.hc-offcanvas-nav .nav-highlight { background: #2e6296 }
.hc-offcanvas-nav .nav-close:focus,
.hc-offcanvas-nav .nav-next:focus,
.hc-offcanvas-nav .nav-back:focus { z-index: 10 }
.hc-offcanvas-nav li:not(.custom-content) a {
padding: 14px 15px;
font-size: 15px;
color: #fff ;
z-index: 1;
background: rgba(0, 0, 0, 0);
border-bottom: 0px solid #2c5d8f}
.hc-offcanvas-nav li:not(.custom-content) a[disabled] {color: rgba(255, 255, 255, 0.5) }
.hc-offcanvas-nav:not(.touch-device) li:not(.custom-content) a:hover {background: #101010 }
.hc-offcanvas-nav ul:first-of-type:not(:first-child)>li:first-child:not(.nav-back):not(.nav-close)>a {
border-top: 1px solid #2c5d8f; margin-top: -1px}
.hc-offcanvas-nav li {text-align: left }
.hc-offcanvas-nav li.nav-close a,
.hc-offcanvas-nav li.nav-back a {    background: #ffffff;  color: #101010;}
.hc-offcanvas-nav li.nav-close a:hover,
.hc-offcanvas-nav li.nav-back a:hover {background: #fff }
.hc-offcanvas-nav li.nav-close:not(:first-child) a,
.hc-offcanvas-nav li.nav-back:not(:first-child) a {margin-top: -1px }
.hc-offcanvas-nav li.nav-parent .nav-item:last-child {padding-right: 35px }
.hc-offcanvas-nav li.nav-parent .nav-item:not(:last-child) {margin-right: 44px}
.hc-offcanvas-nav li.nav-close span,
.hc-offcanvas-nav li.nav-parent .nav-next,
.hc-offcanvas-nav li.nav-back span {
width: 45px;
position: absolute;
top: 0;
right: 0;
bottom: 0;
text-align: center;
cursor: pointer;
transition: background .2s ease}
.hc-offcanvas-nav li.nav-close span::before,
.hc-offcanvas-nav li.nav-close span::after {
content: '';
position: absolute;
top: 46%;
left: 50%;
width: 10px;
height: 10px;
margin-top: -3px;
border-top: 2px solid #101010 ;
border-left: 2px solid #101010 }
.hc-offcanvas-nav li.nav-close span::before {margin-left: -11px; transform: rotate(135deg) }
.hc-offcanvas-nav li.nav-close span::after {transform: rotate(-45deg) }
.hc-offcanvas-nav a.nav-next {border-left: 1px solid #2c5d8f }
.hc-offcanvas-nav .nav-next span::before,
.hc-offcanvas-nav li.nav-back span::before {
content: '';
position: absolute;
top: 50%;
left: 50%;
width: 8px;
height: 8px;
margin-left: -2px;
box-sizing: border-box;
border-top: 2px solid #fff;
border-left: 2px solid #fff;
transform-origin: center}
.hc-offcanvas-nav .nav-next span {position: absolute; top: 0; right: 0; left: 0; bottom: 0}
.hc-offcanvas-nav .nav-next span::before {transform: translate(-50%, -50%) rotate(135deg) }
.hc-offcanvas-nav li.nav-back span::before {transform: translate(-50%, -50%) rotate(-45deg)}
.hc-offcanvas-nav.nav-position-left.nav-open .nav-wrapper {box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2)}
.hc-offcanvas-nav.nav-position-right.nav-open .nav-wrapper {box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2)}
.hc-offcanvas-nav.nav-position-right .nav-next span::before {
margin-left: 0;
margin-right: -2px;
transform: translate(-50%, -50%) rotate(-45deg)}
.hc-offcanvas-nav.nav-position-right li.nav-back span::before {
margin-left: 0;
margin-right: -2px;
transform: translate(-50%, -50%) rotate(135deg)}
.hc-offcanvas-nav.nav-position-top.nav-open .nav-wrapper {box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) }
.hc-offcanvas-nav.nav-position-top .nav-next span::before {
margin-left: 0;
margin-right: -2px;
transform: translate(-50%, -50%) rotate(-135deg) }
.hc-offcanvas-nav.nav-position-top li.nav-back span::before {
margin-left: 0;
margin-right: -2px;
transform: translate(-50%, -50%) rotate(45deg) }
.hc-offcanvas-nav.nav-position-bottom.nav-open .nav-wrapper { box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2) }
.hc-offcanvas-nav.nav-position-bottom .nav-next span::before {
margin-left: 0;
margin-right: -2px;
transform: translate(-50%, -50%) rotate(45deg) }
.hc-offcanvas-nav.nav-position-bottom li.nav-back span::before {
margin-left: 0;
margin-right: -2px;
transform: translate(-50%, -50%) rotate(-135deg) }
.hc-offcanvas-nav.nav-levels-expand .nav-container ul .nav-wrapper,
.hc-offcanvas-nav.nav-levels-none .nav-container ul .nav-wrapper {
box-shadow: none;
background: transparent }
.hc-offcanvas-nav.nav-levels-expand .nav-container ul h2,
.hc-offcanvas-nav.nav-levels-none .nav-container ul h2 {
display: none }
.hc-offcanvas-nav.nav-levels-expand .nav-container ul ul .nav-item,
.hc-offcanvas-nav.nav-levels-none .nav-container ul ul .nav-item {
font-size: 14px }
.hc-offcanvas-nav.nav-levels-expand .nav-container li,
.hc-offcanvas-nav.nav-levels-none .nav-container li {transition: background .3s ease }
.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open { background: #101010 }
.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open>.nav-item-wrapper>a,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open>.nav-item-wrapper>a {
border-bottom: 0px solid #fff }
.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open>.nav-item-wrapper>a:hover,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open>.nav-item-wrapper>a:hover {
background: #101010 }
.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open>.nav-item-wrapper>.nav-next span::before,
.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open>.nav-item-wrapper>a>.nav-next span::before,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open>.nav-item-wrapper>.nav-next span::before,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open>.nav-item-wrapper>a>.nav-next span::before {
margin-top: 2px;
transform: translate(-50%, -50%) rotate(45deg) }
.hc-offcanvas-nav.nav-levels-expand .nav-container .nav-next span::before,
.hc-offcanvas-nav.nav-levels-none .nav-container .nav-next span::before {
margin-top: -2px;
transform: translate(-50%, -50%) rotate(-135deg) }
.hc-offcanvas-nav.rtl a.nav-next {
border-left: none; border-right: 0px solid #fff }
.hc-offcanvas-nav.rtl li.nav-parent .nav-item {
padding-right: 17px; padding-left: 58px }
#main-nav {display: none}
.hc-offcanvas-nav .nav-wrapper-0>.nav-content {padding-bottom: 41px }
.hc-offcanvas-nav h2 {font-weight: 400 }
.hc-offcanvas-nav a {font-size: 16px }
.hc-offcanvas-nav li .custom-message {font-size: 12px }
.hc-offcanvas-nav li .custom-message a {color: #fff; font-size: 13px }
.hc-offcanvas-nav li .custom-message a:hover {text-decoration: none }
.hc-offcanvas-nav.nav-position-top ul.bottom-nav,
.hc-offcanvas-nav.nav-position-bottom ul.bottom-nav {position: relative; border-top: none}
.hc-offcanvas-nav.nav-position-top .nav-wrapper-0>.nav-content,
.hc-offcanvas-nav.nav-position-bottom .nav-wrapper-0>.nav-content {padding-bottom: 0 }
.hc-offcanvas-nav ul.bottom-nav {
position: absolute;
z-index: 10;
bottom: 0;
width: 100%;
display: flex;
flex-wrap: nowrap;
align-items: stretch;
border-top: 0px solid #101010;
background: #101010;}
.hc-offcanvas-nav ul.bottom-nav li {flex: auto}
.hc-offcanvas-nav ul.bottom-nav li a {padding: 10px; text-align: center; height: 100%; border-bottom: none }
.hc-offcanvas-nav ul.bottom-nav li svg {fill: #fff; display: inline-block; vertical-align: middle }
.hc-offcanvas-nav ul.bottom-nav li.prb svg {width: 18px; height: 18px }
.hc-offcanvas-nav.close-no-label:not(.rtl) .nav-title {   padding-right: 60px;}
.hc-offcanvas-nav.close-no-label .nav-title+ul>.nav-close:first-child a {
position: absolute;	width: 45px;	height: 56px;	top: 0;	right: 0;	transform: translateY(-100%) }
/**dark mode css**/
.menu-dark .sub-menu-column .menuheading {  color: #e6e6e6;}
.menu-dark .sub-menu-column ul li a { color: #a2a2a2;}
.menu-dark .sub-menu-section {background: #202020;  border-top: 2px solid #2f2f2f;}
body.active-dark {    color: #a2a2a2; background: #101010;}
.active-dark .-service-list li a { color: #a2a2a2;}
.active-dark h1,.active-dark h2,.active-dark h3,.active-dark h4,.active-dark h5,.active-dark h6 {color: #e6e6e6;}
.active-dark .web-deg {background: #101010;}
.active-dark .navbar-light .navbar-nav .nav-link {color: #e6e6e6;}
.active-dark .hero-bg-bg1:before { opacity: 0.4;}
.active-dark .top-header .navbar .custom-btn {color: #fff; }
.active-dark header .dropdown-menu {background-color: #202020;}
.active-dark .dropdown-item {color: #a2a2a2;}
.active-dark .dropdown-item:focus, .active-dark .dropdown-item:hover {background-color: #202020;}
.active-dark .top-header .sticky {background: #151515;}
.active-dark .about-agency{background: #151515;}
.active-dark .about-agency:before { opacity: .4;}
.active-dark .quote { background: #101010; color: #a2a2a2;  }
.active-dark .service-section { background: #101010;}
.active-dark .service-section:before { background: url(../images/shape/dot-shape.png) #101010;}
.active-dark .service-section .s-block {background: url(../images/shape/shape-7.svg) #1d1d1d;   background-size: 0px;    border: 1px solid #151515;}
.active-dark .shape-bg2:after {opacity: .4;}
.active-dark .shape-bg2:before {opacity: .4;}
.active-dark .statistics-img {  background: rgba(255, 255, 255, 0.3);}
.active-dark .statistics .statnumb  span {color: #e6e6e6;}
.active-dark .portfolio-section:before { opacity: .2;}
.active-dark .isotope_item h4 a {color: #e6e6e6;}
.active-dark .portfolio-section {background: #151515;}
.active-dark .breadcrumb-area .bread-menu li a {    color: #a2a2a2;}
.active-dark .clients-section { background: #101010;}
.active-dark .clients-logo {
background:rgba(255, 255, 255, 0.3);
border: 1px solid #29294c;
-webkit-box-shadow: -20px 20px 40px #ece0f2, 20px -20px 40px #fff8ff;
box-shadow: -20px 20px 40px #101010, 20px -20px 40px #101010;}
.active-dark .work-category {  background: #151515;}
.active-dark .testinomial-section {background: url(../images/shape/shape-9.svg) #101010;  background-size: 0px;}
.active-dark .testinomial-section .owl-carousel .owl-dots {background: transparent;}
.active-dark .enquire-form {    background: #151515;}
.active-dark .form-block label a {color: #00BCD4;}
.active-dark .our-office {background: #101010; }
.active-dark .skyline-img {    background: #191919;}
.active-dark .btn-outline {border: 2px solid #ffffff;    color: #e6e6e6;    background: transparent;}
.active-dark .shape-bg1:after {opacity: .4;}
.active-dark .shape-bg1:before {opacity: .4;}
.active-dark .shape-numm .shape-loc:last-child:after{opacity: .4;}
.active-dark .shape-numm .shape-loc:first-child:before{opacity: .4;}
.active-dark .shape-num .shape-loc:last-child:after{opacity: .4;}
.active-dark .shape-num .shape-loc:first-child:before{opacity: .4;}
.active-dark .footer-row1 { background: #151515;}
.active-dark .email-subs-form input {
color: #e6e6e6;    background: #101010;    border: 1px solid #1f1f1f;
-webkit-box-shadow: -6px 6px 11px #f2f2f2, 6px -6px 11px #fff0e8;
box-shadow: -6px 6px 11px #151515, 6px -6px 11px #151515;}
.active-dark .footer-svg svg path { fill: #151515;}
.active-dark .footer-row2 { background: #101010;}
.active-dark .footer-row3 {background: #101010;}
.active-dark .hline {border-top: 1px solid #181818;}
.active-dark footer:after { opacity: 0.04;}
.active-dark footer:before { opacity: 0.04;}
.active-dark .form-block input, .active-dark .form-block textarea {    color: #e6e6e6;    background: #101010;    border: 2px solid #1f1f1f;}
.active-dark .fl-hero {
background: url(../images/hero/freelancer.png), url(../images/shape/hero-shape.svg),#101010;
background-position: center center, center center;
background-size: cover, cover;
background-repeat: no-repeat, no-repeat;}
.active-dark .about-freelance{background: #151515;}
.active-dark .counter-no span {color: #a2a2a2;}
.active-dark .hexa4 {background: #00243a; border: 2px solid #003c61;}
.active-dark .hexa3 {background: #101844; border: 2px solid #182884;}
.active-dark .hexa2 {background: #174267; border: 2px solid #2369a5;}
.active-dark .hexa1 {background: #311a58; border: 2px solid #54289e;}
.active-dark .bhv-service {background: #151515;}
.active-dark .bhv-service {background: #151515;}
.active-dark .bhv-service .s-block {background: url(../images/shape/shape-7.svg) #191919;  background-size: 0px;  border: 1px solid #191919;}
.active-dark .lead-hero {background: #101010;}
.active-dark .about-lead-gen{background: #101010;}
.active-dark .why-choos-lg:before { opacity: .4;}
.active-dark .statistics-section{background: #151515;}
.active-dark .why-choos-lg { background: #151515;}
.active-dark .active-dark .clients-section.lead-gen-client { background: #151515;}
.active-dark .light-bgs { background: rgba(255, 255, 255, 0.02);}
.active-dark .digi-mkt {background: #101010;}
.active-dark .wide-block {border: 1px solid #191919;    background: #191919;}
.active-dark ::-webkit-input-placeholder { color: #e6e6e6;}
.active-dark :-ms-input-placeholder {   color: #e6e6e6;}
.active-dark ::placeholder {  color: #e6e6e6;}
.active-dark .navbar .mobilemenu i { color: #a2a2a2;}
.active-dark .res-menu .modal-header {background: #151515;}
.active-dark .res-menu .modal-dialog {background: #101010;}
.active-dark .res-menu .modal-body { background: #101010;}
.active-dark .res-menu .menu .nav-link { color: #e6e6e6;}
.active-dark .res-menu .dropdown-toggle::after {border-top: 1px solid #e6e6e6; border-right: 1px solid #e6e6e6;}
.active-dark .res-menu .dropdown-item { background-color: #101010;}
.active-dark .res-menu .dropdown-menu { background-color: #101010;}
.active-dark .res-menu .modal-content.full {background: #101010;}
.active-dark .hero-card-web {background: #101010;}
.active-dark .about-sec-rpb { background: #151515;}
.active-dark .service-sec-list { background: #191919;    border: 1px solid #191919;}
.active-dark .-cta-btn p { color: #e6e6e6;}
.active-dark .clients-section- { background: #101010;}
.active-dark .badges-section { background: #181818;}
.active-dark .cta-area { background: #181818;}
.active-dark .footer-brands { background: #151515;}
.active-dark .shape { opacity: .4;}
.active-dark .single-blog- .content .title a {  color: #e6e6e6;}
.active-dark .single-blog- .content .title a:hover {  color: #ff1f8e ;}
.active-dark::-webkit-scrollbar-track {    background: #101010;}
.active-dark .main-header.sticky { background: #101010;}
.active-dark .main-header.sticky .custom-nav .nav-list li a.menu-links {  color: #e6e6e6;}
.active-dark .custom-nav .nav-list li a.menu-links {  color: #e6e6e6;}
.active-dark .contact-info- { box-shadow: 0 0 5px rgba(0,0,0,.3);}
.active-dark .hrbg {  background: #151515;}
.active-dark .contact-info- .inquiry-card-nn {   border-top: 1px solid #1c2b42;}
.active-dark .contact-info- .contct-heading { color: #e6e6e6;}
.active-dark .contact-info- .inquiry-card-nn ul li a { color: #e6e6e6; }
.active-dark .contact-info-:after { border-bottom: 7px solid #202020;}
.active-dark .contact-info- { background: #202020;}
.active-dark .footer-address-list li {  color: #a2a2a2;}
.active-dark .nav-bg-b.main-header .mega-white-logo { display: inline-block;}
.active-dark .main-header.sticky .mega-darks-logo {  display: none;}
.active-dark .main-header.sticky .hc-nav-trigger span,
.active-dark .main-header.sticky .hc-nav-trigger span::before,
.active-dark .main-header.sticky .hc-nav-trigger span::after {  background: #fff;}
.active-dark .hc-nav-trigger span, .active-dark .hc-nav-trigger span::before, .active-dark .hc-nav-trigger span::after { background: #fff;}
.active-dark .nav-item.dropdown:hover a.nav-link{ color: #101010;}
.active-dark .footer-address-list li a {  color: #a2a2a2;}
.active-dark .footer-social-media-icons a {  color: #e6e6e6;}
.active-dark .breadcrumb-area:before {  opacity: .92;  background: #101010;}
.active-dark .deep-dark{background: #101010 !important;}
.active-dark .light-dark{background: #151515 !important;}
.active-dark .dim-dark{background: #181818;}
.active-dark .link-hover a {  color: #a2a2a2;}
.active-dark .breadcrumb-areav2:before { opacity: .92; background: #101010;}
.active-dark .blog-post-tag span {color: rgb(106 106 142);}
.active-dark .blog-share-icon span { color: rgb(106 106 142);}
.active-dark .blog-post-tag a { color: #6a6a8e; border-color: rgb(64 64 97);}
.active-dark .rd-quote { border-left: 4px solid #fc4899;  background: #151515;}
.active-dark .form-blog { background: #151515;}
.active-dark .author-block { background: #151515;}
.active-dark .post-navigation h4 a { color: #a2a2a2;}
.active-dark .tabs a { background: #252533;}
.active-dark .recent-post .post-info h5 a { color: #a2a2a2;}
.active-dark .icon-card- { background: #101010;  border: 1px solid #29294c;}
.active-dark .card-stats .statistics {  background: #101010; -webkit-box-shadow: none; box-shadow: none; border: 1px solid #29294c;}
.active-dark .case-study{background: #151515}
.active-dark .project-platform-used {  margin: 0 10px 0 0px; }
.active-dark .-shadow {  -webkit-box-shadow: none; box-shadow: none;}
.active-dark .info-text-block h4 a {  color: #a2a2a2;}
.active-dark .team {background: #151515}
.active-dark .about-sec{background: #151515}
.active-dark .accordion .card-body { background: #101010;}
.active-dark .card-1 {    border: 1px solid #151515;}
.active-dark .card-1 .card-header { background-color: #151515;}
.active-dark .tabs-layout .nav-tabs .nav-link.active {    color: #ffffff;    background-color: #00baff;    border-color: #00baff; }
.active-dark .tabs-layout .nav-tabs .nav-link {   border: 1px solid #151515;  color: #6a6a8e;  background-color: #202040; }
.active-dark .layer-div:before { opacity: .1;}
.active-dark section.error{    background: #101010;}
.active-dark .contact-page{    background: #151515;}
.active-dark .contact-location{    background: #101010;}
.active-dark .form-block select {   color: #738ba0;    background: #202040;   border-bottom: 2px solid #29294c; }
.active-dark .skype-card {    background: #202040;}
.active-dark .email-card {  background: #251738;}
.active-dark .contact-card { background: #202040;}
.active-dark .portfolio-meta ul li i { color: #a2a2a2;}
.active-dark .portfolio-meta ul li p { background: #151515;  color: #a2a2a2;}
.active-dark .portfolio-meta ul li { background: #151515; border-bottom: 1px solid #101010;}
.active-dark .filters .filter-menu li.is-checked {  background: #f95b94;  color: #fff;}
.active-dark .filters .filter-menu li { color: #a2a2a2;  background: #2d304c;}
.active-dark .portfolio-item-info h3 a {    color: #a2a2a2;}
.active-dark .portfolio-block { background: #151515;}
.active-dark .why-choose {background: #101010;}
.active-dark .ps-block span {  border: 1px solid #2b2b7d;   background: #24213c;}
.active-dark .service { background: #101010;}
.active-dark .reviews-card { background: #181818; border: 1px solid #29294c;}
.active-dark .reviews-block { background: #151515;}
.active-dark .featured-project { background: #101010;}
.active-dark .block-a1{background: #151515;}
.active-dark .pricing-table.best-plan img {   background: #2d1963;     border: 8px solid #382371;}
.active-dark .pricing-table img {  background: #231744;     border: 8px solid #2d1963;}
.active-dark .pricing-table .inner-table .details {   border-top: 1px solid #20203e;}
.active-dark .best-plan { background: #161633 !important;}
.active-dark .pricing-table {    -webkit-box-shadow: none; box-shadow: none; background: #18182f; border: 1px solid #29294c;}
.active-dark .bg-shape:before {  opacity: .3;}
.active-dark .bg-shape:after { opacity: .3;}
.active-dark .s-block {  background: #191919;   border: 1px solid #191919;}
.active-dark .service-block{background: #101010;}
.active-dark .tech-icons a { border: 4px solid #101010; background: #101010;}
.active-dark .techonology-used- {   background: #151515;}
.active-dark .image-block img {
-webkit-box-shadow: -20px 20px 35px #101010, 20px -40px 35px #101010;
box-shadow: -20px 20px 35px #101010, 20px -40px 35px #101010;
-webkit-border-radius: 8px;}
.active-dark .image-block1 img {
-webkit-box-shadow: -20px 20px 35px #151515, 20px -40px 35px #151515;
box-shadow: -20px 20px 35px #151515, 20px -40px 35px #151515;
-webkit-border-radius: 8px;}
.active-dark .statistics-wrap { background: #151515;}
.active-dark .copyright{ color: #a2a2a2;}
.active-dark .copyright a{ color: #a2a2a2;}
.active-dark .entry-blog a {  color: #a2a2a2;}
.darkbg .navbar-light .navbar-nav .nav-link {  color: #e6e6e6;}
.active-dark .modal-content { background-color: #101010;}
.active-dark .fdgn2 input,.active-dark .fdgn2 select,.active-dark .fdgn2 textarea {  border: 2px solid #404040!important;}
.active-dark .modal-header { border-bottom: 1px solid #404040;}
.active-dark .popup-modal1 .modal-dialog { background: #101010;}








/* *************************** */
.statnumb h4{
    font-size: 45px;
    font-family: 'Poppins', sans-serif;
    color: #050748;
    font-weight: 700;
    display: inline-block;
}

.main-header {
    z-index: 100;
}

.MuiDrawer-paper {
    width: 250px;
}

.listbutton {
    width: 100% !important;
    color: #fff !important;
}
.list-head {
    text-align: center;
    background-color:  #fff;
    color : #101010 !important;
}

.MuiDrawer-paperAnchorLeft {
    background-color: black !important;

}

.Top-nav-list {
    background-color:  #fff;
}

.MuiAccordion-rounded {
    background-color: black  !important;
}

.accord {

    width : 220% !important
}


.bg-shape-dez::after, .bg-shape-dez::before {
    display : none
}



.anim-img-services {
    animation-name: moveimg;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: relative;
}

@keyframes moveimg {
 0% { top: 0px }
 50% {top: -15px}   
 100% {top: 0px}   
}