/**width 1920 to 992**/
@media screen and (max-width:1920px) and (min-width:992px) {
.dropdown:hover>.dropdown-menu {
display: block;
width: max-content;
padding: 10px 0px;
right: 0;
left: auto;}
.dropdown>.dropdown-toggle:active {pointer-events: none}
}
/**width 1200**/
@media (min-width:1200px) {
.container,.container-lg,.container-md,.container-sm,.container-xl {max-width: 1200px}
}
/**width 1370**/
@media only screen and (max-width:1370px) {
.navbar-light .navbar-nav .nav-link {
padding: 0px 12px;
}
.hero-service-cards {margin-top: 120px;}
.hero-heading-sec > p {margin: 30px 0;}
.image-sr-mm {height: 200px; width: 100%;}
.hero-heading-sec h2 {font-size: 45px; line-height: 57px;}
.hero-card-web {padding-top: 120px; padding-bottom: 100px; height: 780px;}
.hero-main-rp {padding: 0 30px 0 30px; }
.hero-section {padding-top: 100px; padding-bottom: 100px; height: 750px}
}
/**width 1150**/
@media only screen and (max-width:1150px) {
::-webkit-scrollbar {width:4px;}
::-webkit-scrollbar-track { background: #0d0c40;}
.mobile-menu2{display: block;}
.service-sec-list h5 {font-size: 15px; line-height: 24px;}
.-service-list li a {font-size: 11px;}
.-service-list li {padding: 0 3px;}
.custom-nav .nav-list {display: none;}
.top-header .navbar-light .navbar-nav .nav-link {padding: 0 12px}
.header-heading h1 {font-weight: 700; margin-bottom: 30px; font-size: 45px; line-height: 57px}
}
/**width 911**/
@media only screen and (max-width:991px) {
.popup-modal1 .modal-dialog {min-height: 100%; margin: 0 0 0 auto; background: #fff; }
.popup-modal1 .modal-content{border: 0;}
.card-stats .statistics { padding: 15px;}
.pr--100 { padding-right: 0px;}
.pl--100 { padding-left: 0px;}
.pl--50 { padding-left: 0px;}
.pr--50 { padding-right: 0px;}
.dg-hero-img0 { transform: translateZ(0px); margin-top: 30px;}
#testimonials-avatar{text-align: center;}
.dot-c1 {
position: inherit;
width: 80px;
height: 80px;
border-radius: 100px;
border: 0;
width: 46% !important;
margin: 20px 0 0 0;
}
.div-portfolio- {
padding:30px 20px;
}
.itm-media-object{text-align: left;}
.ag-dg2 {
border-radius: 8px 8px 50px 8px;
right: 0px;
}
.ag-dg1 {
border-radius: 8px 8px 8px 50px;
left: 0px;
}
.shpdez2 {
width: 60px;
height: 60px;
}
.img-block-ca2 .abt-ca2 {
border-radius: 50px 00px 50px 0px;
position: relative;
z-index: 5;
width: 90%;
}
.service-sec-list, .service-sec-brp, .img-block {margin-top: 30px;}
.posiqut { opacity: .2;  top: 0;}
.tcd {
padding: 40px 30px;
border-radius: 40px 8px 8px 8px;
margin-top: 30px;
}
.pl50 { padding-left: 0px !important;}
.div-item-info{text-align: center; margin-top: 30px;}
.testinomial-section-app, .minimal-portfolio{text-align: center;}
.client-logoset{padding:0 15px;}
.about-sec-app	.text-l {   text-align: center !important;}
.cta-card{text-align: center;}
.onloadpage .pre-text- { width: 88%;}
.video-intro-pp{margin-top: 20px;text-align: center;}
.content-sec, .-content-sec  {   display: block !important;}
.mr25 { margin-right: 0;}
.mobile-app-{text-align: center;}
.hero-heading-sec2 h1 {  font-size: 40px;  line-height: 50px;}
.sctxt { font-size: 40px;}
.portfolio-block { padding: 20px;     display: block;}
.portfolio-item-info {  width: 100%;  padding-right: 0;}
.portfolio-item-image { width: 100%;  text-align: center; margin-top: 30px;}
.portfolio-item-info .reviews-card { padding: 20px;}
.header-heading .title-{text-align: center;}
.servie-key-points{margin-top: 30px;}
.servie-key-points h4{text-align: center;}
.service-desc-{text-align: center;}
.m-mt50{margin-top: 50px;}
.m-mt30{margin-top: 30px;}
.lead-gen-client .clients-logo {
max-height: max-content;
min-height: auto;
padding: 30px 10px;}
.main-header.sticky {height: 65px;}
.main-header.sticky .dsk-logo {line-height: 65px;}
.main-header.sticky .nav-brand img {max-height: 55px;}
.footer-badges- {margin-top: 20px;}
.footer-brands{text-align: center}
.ftr-brand-pp .btn-main {
width: max-content;
margin: 20px 0 0 0;
display: block;}
.review-icons a {margin-top: 30px;}
.review-ref {
display: block;
align-items: center;
justify-content: center;
text-align: center;}
.service-sec-brp    p {font-size: 15px;line-height: 24px;}
.m-mb30{margin-bottom: 30px;}
.mt30- {margin-top: 0; }
.m-t-c, .text-l{text-align: center !important;}
.col-lg-3-cus {width: 50%;}
.col-lg-6-cus {width: 50%;}
.hero-service-cards {margin-top: 40px;}
.hero-content-sec {display: block;text-align: center;margin-top: 40px;}
.title-hero-oth p {font-size: 20px;margin: 20px 0 0 0px;}
.hero-heading-sec > p {
color: #fff000;
font-size: 18px;
max-width: 100%;
margin: 20px 0;}
.hero-heading-sec, .end-footer-, .footer-link--{text-align: center}
.hero-heading-sec h2 span {display: inline-block;}
.hero-card-web {padding-top: 150px;padding-bottom: 100px;height: auto;}
.breadcrumb-area {padding: 120px 0px 60px 0px;}
.common-heading h2,
.common-heading h4,
.common-heading>span,
.header-heading p,
.image-block, .single-image {    text-align: center  }
.single-image {margin: 30px auto 0;display: block}
.lead-intro-{margin: 30px auto 0;display: block}
.portfolio-section:before {height: 300px}
.wide-block:after {background-size: 150px;background-position: right bottom;opacity: .6}
.block-space- {max-width: 70%}
.wide-block {padding: 20px}
.navbar .mobile-menu {display: inline-block}
.mobile-none {display: none}
.navbar-light .navbar-toggler {color: #000;border-color: transparent;}
.custom-btn {padding: 0 25px!important;line-height: 42px}
.header-heading h1 {
font-weight: 700;
margin-bottom: 30px;
font-size: 38px;
line-height: 50px;
text-align: center}
.header-heading p {font-size: 18px;line-height: 28px;margin-bottom: 35px}
.btn-main {
width: max-content;
margin: 0 auto;
display: block}
.hero-section {padding-top: 120px;padding-bottom: 80px;height: auto}
.image-block {margin-bottom: 30px}
.pad-tb {padding-top: 80px;padding-bottom: 80px}
.user- {margin: 0 auto;display: table}
.opl ul li, .statistics {margin-bottom: 30px}
.statistics {text-align: left}
.about-agency p,
.about-image,
.block-1 .small,
.block-1 .statnumb,
.case-study1 p,
.case-study3 p,
.common-heading.pp,
.email-subs,
.filter-menu,
.in-stats .statistics,
.office-text,
.service p,
.testimonial-card .t-text {text-align: center}
.small {margin-top: 0}
.small .statistics span {color: #050748}
.statistics-img {width: 80px;    height: 80px;}
.small .statistics-img {width: 80px;    height: 80px;}
.icon-set, .opl ul li {width: 33.333% }
.small .statistics span, .statistics span {font-size: 35px}
.opl ul li:last-child {margin-bottom: 0}
.testinomial-section {background-size: 0}
.testimonial-card .t-text, .work-card-set, .img-design {margin-top: 30px}
.testinomial-section .owl-carousel .owl-dots {position: relative;width: 100%;text-align: center;}
.pl25 {padding-left: 0!important}
.client-thumbs {margin: 30px auto 0;display: table}
.enquire-image {margin-top: 30px}
.office-card {margin-bottom: 60px}
.office-text p {padding: 0;margin: 0 0 20px}
.email-subs p {margin-bottom: 30px}
.footer-social-media-icons a {margin: 0 10px}
.footer-svg {margin: -3px 0 0}
.block-1 .small {margin-top: 50px}
.in-stats .statistics {margin-bottom: 0;display: inline-block}
.block-1 .statnumb {margin: 0}
.in-stats .statistics span {font-size: 35px;color: #ff1f8e}
.about-image {margin-bottom: 30px}
.ps-block {padding: 0;margin: 30px 0 0}
.contact-details {margin-top: 60px}
.filter-menu, .m-mt30 {margin-top: 30px}
.showin-mobile{display: block; }
.fl-hero {
background-position: center center, center center;
background-size: 0px, cover;
background-repeat: no-repeat, no-repeat;}
.active-dark .fl-hero {
background: url(../images/hero/freelancer.png), url(../images/shape/hero-shape.svg),#101010;
background-position: center center, center center;
background-size: 0px, cover;
background-repeat: no-repeat, no-repeat;}
.counter-facts{margin: 60px 0 0 0;text-align: center;}
.s-block { padding: 50px 15px;}
.mmt30{margin-top:30px;}
.singl-heripic{margin-top:30px;}
.itm-media-object h4 {text-align: left;}
.lead-gen-client .clients-logo {    margin: 0 10px;}
}
/**width 767**/
@media only screen and (max-width:767px) {
.psforgt{margin-top: 10px;  display: block;  text-align: center;}
.social-btnnxx{display: flex;}
.login-form-div {
max-width: 100%;
margin: 0 15px;
background: #fff;
padding: 30px 20px;
border-radius: 8px;
}
.hero-digital-agency-1 .header-heading h1 {
font-size: 40px;
line-height: 50px;
}
.video-review + .video-review{margin-top: 30px;}
.video-testimonial {  display: block;}
.mr15 { margin-right: 0;}
.itm-media-object .media .img-ab- { width: 55px;}
.-content-sec .title-hero-oth p { margin: 20px 0 0px 0px;}
.app-hero-bnr, .img-hro{margin-top: 30px;}
.shape-c1, .shape-c2, .shape-c3 {    opacity: 0.3;}
.apppg li p { font-size: 15px;}
.industry-workfor {
padding: 15px;
display: block;
text-align: center;
margin: 0 auto 15px;}
.industry-workfor img {
width: 45px;
}
.industry-workfor h6 {
margin: 5px 0 0 0px;
font-size: 14px;
line-height: 20px;
}
.light-bgs {  padding: 30px 0;}
.s-block-content-large {margin: 0 0 0 0px; width: 100%; text-align: center;}
.s-card-icon-large {width: 150px; margin: 0 auto 20px;}
.wide-sblock {display: block; text-align: left; padding: 30px 20px;}
.s-block-content {margin: 0 0 0 0px; text-align: center;}
.footer-link-v2 a {padding: 14px 0;}
.clients-logo {  padding: 60px 15px;}
.cta-call a { font-size: 20px;}
.itm-media-object .media-body {  margin-left: 15px; }
/*.itm-media-object .media img {width: 50px;}*/
.badges-content p {  padding: 0 0px;  }
.footer-badges- li a img {width: 100%;}
.service-sec-list h5 {font-size: 18px; line-height: 28px;}
.-service-list li a {font-size: 13px;}
.skyline-img {text-align: center;}
.-cta-btn p {margin: 0 0px 30px 0px; font-size: 25px; line-height: 35px;}
.free-cta-title {display: block;}
.col-lg-6-cus {width: 100%; text-align: center;}
.col-lg-3-cus {width: 100%; text-align: center;}
.service-slide {max-width: initial;}
.about-freelance p{text-align:center;}
.about-lead-gen p{text-align:center;}
.why-choos-lg p{text-align:center;}
.why-choos-lg .media-body p{text-align:left;}
.follow-label h6 {  margin: 0 0px 10px 0px;}
.follow-label {display: block;text-align: center;}
.follow-label h6:after { content:none;}
.follow-label a { font-size: 20px;}
.tabs-layout .nav-tabs .nav-link {padding: 12px 35px; margin: 0 auto 10px}
.tabs-layout .nav-tabs .nav-item {margin-bottom: 5px; display: contents}
.about-statistics .statistics, .form-blog {margin-bottom: 30px}
.order1 {order: 1}
.order2 {order: 2}
.pad-tb {padding-top: 60px; padding-bottom: 60px}
.icon-set, .opl ul li {width: 50%}
.email-subs-form button {
width: 55px;
height: 55px;
border-radius: 100px;
font-size: 0;
line-height: 55px;
padding: 5px;
text-align: center}
.email-subs-form input {width: 100%; height: 65px; padding: 0 30px}
.shape-a1 {bottom: -130px; left: -50px}
.shape-a2 {bottom: -30px; right: -70px}
.portfolio-meta {margin-top: 30px }
.pr25 {padding-right: 0!important}
.form-blog {background: #fafafb; padding: 30px 15px}
}
/**width 576**/
@media (min-width:576px) {
.modal-dialog {max-width: 400px; margin: 1.75rem auto}
}
/**width 580**/
@media (max-width:580px) {
.ftr-brand-pp .btn-main {width: max-content; margin: 20px auto 0; display: block;}
.ftr-brand-pp{text-align: center;}
}
/**width 520**/
@media only screen and (max-width:520px) {
.service-point-2 li {  float: none;     width: 100%;}
.hero-heading-sec h2 {font-size: 35px; line-height: 45px;}
.footer-badges- {display: flow-root; justify-content: space-between;}
.footer-badges- li {display: block; width: 50%; float: left; padding: 0 6px;}
.m-pad{padding: 0;}
.main-header {padding: 0 15px;}
.title-serv-c {text-align: center;}
.author-block .media, .contact-details .media {display: block}
h1 {font-size: 32px; line-height: 42px;}
.bread-title h2,h2 {font-size: 26px; line-height: 36px;}
h3 {font-size: 24px; line-height: 34px}
h4 {font-size: 20px; line-height: 30px}
h5 {font-size: 18px; line-height: 26px}
h6 {font-size: 16px; line-height: 24px}
.small .statistics span, .statistics span { font-size: 30px }
.custom-control label {font-size: 12px}
.contact-details .media-body {padding: 0; margin: 20px 0 0}
.shape-a1 {bottom: -130px; left: -150px}
.filters .filter-menu li {padding: 20px 12px;  margin-bottom: 6px;}
.comments-block .user-info h5 span {
font-size: 15px;
text-align: left;
float: none;
color: #6a6a8e;
display: block;
padding: 5px 0 0}
.author-block .user-info {margin: 15px 0 0}
.replied {padding-left: 0}
}
/**width 350**/
@media only screen and (max-width:350px) {
.header-heading h1 {font-size: 30px; line-height: 40px}
h2 { font-size: 24px; line-height: 32px}
.info-card a {font-size: 16px}
.custom-btn {padding: 0 14px!important; line-height: 42px}
.filters .filter-menu li {font-size: 12px}
.case-study4 .card-stats .statistics {padding: 10px}
.case-study4 .statistics h3 {font-size: 15px; margin: 10px 0 0}
}


/**start update 1.7 start from here**/
@media only screen and (max-width:767px) {

.rpb-cart-table table tr td.rpbrs-titl::before, .rpb-cart-table table tr td.rpbrs-titl::before {
   content: attr(data-title) ": ";
    font-weight: 700;
    float: left;
}
.rpb-cart-table table tr td, .rpb-cart-table table tr td {
    display: block;
    text-align: right!important;
}
.rpb-cart-table table  thead, .rpb-cart-table table thead {
    display: none;
}
.rpb-cart-table table .product-thumbnail{
	display: none;
}
.cart-pg-coupon input, .cart-pg-coupon button {
    width: 48%;}

.update-cart button{width: 100%;}
.post-info a.btn-main {
	width: max-content;
    margin: 10px 0 0 0;
    display: block;
}

}

@media only screen and (max-width: 520px){
	.btn-grp a + a{margin-top: 15px;}
	.btn-grp a {width: 100%; margin: 0;}
.custom-control label {
    font-size: 12px;
    padding: 0 0 0 8px;
    text-indent: 1px;
    line-height: 16px;
}

}

/**end update 1.7 start from here**/